import React, { Component, useEffect } from "react";
import { useParams } from "react-router-dom";
import ApiCalls from "../../shared/api";
import router from "../../shared/top-level-declarations/history";

const OldToNewQuest = () => {
  let { questUuid } = useParams();

  // This page will fetch the quest data and get the org id from it and redirect to the new quest page '/c/:communityId/q/:questUuid?...params'

  const _redirectToNewQuest = async () => {
    try {
      const { data: quest } = await ApiCalls.quest.public.fetchQuest(questUuid);
      if (quest) {
        console.log("quest :>> ", quest);
      }
      router.navigate(`/c/${quest.owningOrganizationId}/q/${questUuid}`);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    _redirectToNewQuest();
  }, []);

  return <></>;
};

export default OldToNewQuest;
