/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ICONS from "../../../../assets/quester-icons";
import LinkPreviewImage from "../../../../components/core/link-previewer/link-previewer";
import SaveButton from "../../../../components/save-button/save-button";
import AuthHelper from "../../../../shared/helper-methods/auth-helper";
import useKeyCloakSafe from "../../../../shared/hooks/useSafeKeycloak";
import CommunityUtils from "../../community-utils";
import CommunityItem from "../../../../components/community-item/community-item";
import Button from "../../../../components/buttons/buttons";
import MetaStoreActionTypes from "../../../../stores/meta-store/action-types";
import { MetaStoreActions } from "../../../../stores/meta-store/actions";
import SponsorUtils from "../../../../features/sponsorship/sponsor-utils";
import SponsorResource from "../../../../features/sponsorship/components/sponsor-resource/sponsor-resource";
import "./community-resources-viewer.scss";
import CommunityTabView from "../community-tab-view/community-tab-view";
import CollectionCard from "../../../collection/components/collection-card/collection-card";
import FeaturedCollectionsViewer from "../../../collection/components/featured-collections-viewer/featured-collections-viewer";
import CollectionsGridViewer from "../../../collection/components/collections-grid-viewer/collections-grid-viewer";
import AddNewCollection from "../../../../components/add-new-collection/add-new-collection";
import AddItemFlow from "../../../../components/add-item-flow/add-item-flow";
import ResourceWizard from "../../../resource/components/resource-wizard/resource-wizard";

const CommunityResourcesViewer = ({ infiniteRef }) => {
  const {
    activeCommunityResources,
    activeCommunityResourcesPagination,
    isCommunityResourcesLoaderActive,
    isCollectionsLoaderActive,
  } = useSelector((state) => state.communityStore);
  const { isRatingExpanded } = useSelector((state) => state.metaStore);
  const [sponsorItemIndices, setSponsorItemIndices] = React.useState({});

  const dispatch = useDispatch();
  const _toggleAllRating = () => {
    dispatch(
      MetaStoreActions.staticActions.toggleRatingExpanded(!isRatingExpanded)
    );
  };

  const _generateSponsorItemIndices = () => {
    let indices = SponsorUtils.seededRandom(
      2,
      10,
      activeCommunityResourcesPagination?.pageNo,
      activeCommunityResourcesPagination?.pageSize
    );
    setSponsorItemIndices(indices);
  };

  useEffect(() => {
    _generateSponsorItemIndices();
  }, [activeCommunityResourcesPagination?.pageNo]);

  // const [scroll, setScroll] = useState(false);
  // useEffect(() => {
  //   window.addEventListener("scroll", "communityPageMainContent", () => {
  //     setScroll(window.scrollY > 50);
  //   });
  // }, []);

  return (
    <>
      {/* DEFAULT HEADER */}
      <div className="communityUnderHeader d-flex align-items-center justify-content-between">
        {/* <div className="sortBy">
          <div className="icon">{ICONS.ascSort}</div>
          <div className="text">Newest</div>
        </div>

        <span>·</span> */}

        <div className="count">
          {activeCommunityResources.length ? (
            <>
              Showing {activeCommunityResources.length} of{" "}
              {activeCommunityResourcesPagination.totalElements} items
            </>
          ) : (
            <></>
          )}
        </div>

        {!(
          activeCommunityResources?.length && !isCommunityResourcesLoaderActive
        ) ? (
          ""
        ) : (
          <div className="buttons">
            <Button
              size={"extraSmall"}
              icon={ICONS.infoCircle}
              iconPos={"left"}
              type={"default"}
              onClick={_toggleAllRating}
              text={isRatingExpanded ? "Hide all ratings" : "Show all ratings"}
              extraClasses={"showAllRatingsButton"}
            />
          </div>
        )}
      </div>

     

      {/* mobile */}

      {activeCommunityResources?.length ? (
        <>
          <div className="communityItems">
            {activeCommunityResources.map((item, index) => {
              let showSponsorItem = false;
              if (sponsorItemIndices[index]) {
                if (SponsorUtils.checkIfSponsorCommunity()) {
                  showSponsorItem = true;
                }
              }
              return (
                <>
                  {showSponsorItem ? <SponsorResource /> : <> </>}
                  <CommunityItem
                    key={index}
                    communityItem={item}
                    teamResource={true}
                  />
                </>
              );
            })}
          </div>
        </>
      ) : (
        <></>
      )}

      {!activeCommunityResources?.length &&
      !isCommunityResourcesLoaderActive ? (
        <>
          <div className="emptySearchResult">
            <div className="notFoundTitle">
              We could find anything about that in this community yet
            </div>
            <div className="notFoundContent">
              <div className="text">
                It's not you, <br /> it's me...
              </div>
              <div className="image">{ICONS.questerIconSad}</div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      {isCommunityResourcesLoaderActive ? <></> : <></>}

      {/* NO SEARCH RESULT */}
      {/* <div className="communityUnderHeader">
        <div className="count">
          No results for: 'Sushi recipe'{" "}
          <span className="text-decoration-underline ps-3" type="button">
            Clear
          </span>
        </div>
      </div> */}
    </>
  );
};

export default CommunityResourcesViewer;
