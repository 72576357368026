import React, { Component, useState, useRef } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import ICONS from "../../assets/quester-icons";
import { Player } from "@lottiefiles/react-lottie-player";
import AuthHelper from "../../shared/helper-methods/auth-helper";
import { useDispatch, useSelector } from "react-redux";
import { MetaStoreActions } from "../../stores/meta-store/actions";
import router from "../../shared/top-level-declarations/history";
import CommunityIcon from "../../entities/community/components/community-icon/community-icon";
import useKeyCloakSafe from "../../shared/hooks/useSafeKeycloak";
import DailyStreak from "../daily-streak/daily-streak";
import Button from "../buttons/buttons";
import "./sidebar.scss";
import FeedbackModal from "../../features/feedback-collector/feedback-modal/feedback-modal";
import { EventEmitter } from "../../shared/utils/event-emitter";
import SiteWiseEvents from "../../declarations/site-wise-events";
import MobileDrawer from "../mobile-drawer/mobile-drawer";
import Notification from "../notification/notification";
import Notifications from "../../features/notifications/components/notifications/notifications";
import NotificationUtils from "../../features/notifications/notification-utils";

const SideBar = (props) => {
  const [lightMode, setLightMode] = useState(true);
  const [motionMode, setMotionMode] = useState(true);
  const [morePopupOpen, setMorePopupOpen] = useState(false);
  const [notificationPopupOpen, setNotificationPopupOpen] = useState(false);
  const [searchPopupOpen, setSearchPopupOpen] = useState(false);
  const { userOrgs } = useSelector((state) => state.userStore);
  const { activeCommunityId } = useSelector((state) => state.communityStore);
  const { keycloak } = useKeyCloakSafe();
  const { unreadNotificationCount } = useSelector(
    (state) => state.notificationStore
  );

  const { metaStore } = useSelector((state) => state);
  const dispatch = useDispatch();
  let isLightMode = true;
  if (metaStore?.isDarkModeEnabled) {
    isLightMode = false;
  }

  const _onThemeChange = (e) => {
    e.stopPropagation();
    dispatch(
      MetaStoreActions.staticActions.toggleDarkMode(
        !metaStore?.isDarkModeEnabled
      )
    );
  };

  const _openCommunity = (e) => {
    router.navigate("/c/1");
  };

  const _navigateTo = (e, url) => {
    e.stopPropagation();
    window.open(url, "_blank");
  };

  const _onNotificationBellClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // Hide the mobile drawer
    _closeMobileDrawer();
    EventEmitter.dispatch(SiteWiseEvents.OPEN_NOTIFICATIONS_DESKTOP);
    if (unreadNotificationCount > 0) {
      NotificationUtils.clearUnreadCount();
    }
  };

  const _closeMobileDrawer = (e) => {
    document.body.classList.remove("sidebarShow");
    document.body.classList.add("sidebarClose");
    setTimeout(
      function () {
        document.body.classList.remove("sidebarClose");
      }.bind(this),
      800
    );
  };

  const notificationBellRef = useRef(null);
  const userProfileRef = useRef(null);
  const moreIconRef = useRef(null);

  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [feedbackSentShow, setFeedbackSentShow] = useState(false);

  return (
    <>
      <div
        className="sideBar"
        onClick={(e) => {
          _closeMobileDrawer();
        }}
      >
        <div className="sideBarInner">
          <div className="logo" onClick={(e) => router.navigate("/")}>
            <div className="desktopLogo">{ICONS.questerIcon}</div>
            <div className="mobileLogo">{ICONS.questerIconWithText}</div>
          </div>

          <div className="middleSection">
            <div className="topIcons">
              {keycloak?.authenticated ? (
                <div className="notificationIcon">
                  <div
                    className="icon"
                    title="Notifications"
                    onMouseEnter={(e) => notificationBellRef.current.play()}
                    onClick={_onNotificationBellClick}
                  >
                    <Player
                      src={require("../../assets/animations/notification-bell.lottie.json")}
                      className="notificationBell"
                      loop={false}
                      autoplay={false}
                      ref={notificationBellRef}
                    />
                  </div>
                  {unreadNotificationCount > 0 ? (
                    <div className="newIndicator"></div>
                  ) : (
                    <></>
                  )}

                  <div className="textLabel" onClick={_onNotificationBellClick}>
                    Notification
                  </div>

                  <Notifications showMobile={false} />
                </div>
              ) : (
                <></>
              )}

              <div
                className="profileIcon"
                title={
                  AuthHelper.isLoggedIn()
                    ? "My profile"
                    : "Login to access your profile"
                }
                onClick={(e) =>
                  AuthHelper.isLoggedIn()
                    ? router.navigate("/my-profile")
                    : AuthHelper.login()
                }
              >
                <div
                  className="icon"
                  onMouseEnter={(e) => userProfileRef.current.play()}
                >
                  <Player
                    src={require("../../assets/animations/user-profile.lottie.json")}
                    className="userProfileBell"
                    loop={false}
                    autoplay={false}
                    ref={userProfileRef}
                  />

                  {/* only for mobile + only when logged in */}
                  <img
                    src={require("../../assets/images/cat-cute.jpg")}
                    alt=""
                  />
                  <div className="dailyStreakWrapper">
                    {" "}
                    <DailyStreak />{" "}
                  </div>
                  {/* /only for mobile + only when logged in */}
                </div>

                <div className="textLabel">My profile</div>
              </div>
            </div>

            <div className="bottomIcons">
              {/* <div className="searchButton">
                <div className="searchIcon" title="Discover" onClick={(e) => setSearchPopupOpen(true)}>{ICONS.globeStand}</div>


                <div className={"searchPopup " + (searchPopupOpen ? "show" : "")}>
                    <div className="overlay" onClick={(e) => setSearchPopupOpen(false)}></div>
                    
                    <div className="searchPopupInner">
                        <div className="headerSearch">
                            <div className="icon">{ICONS.globeStand}</div>
                            <div className="title">SEARCH ACROSS ALL COMMUNITIES</div>
                        </div>

                        <div className="searchInputFilter">
                            <div className="searchBar">
                                <div className="icon">{ICONS.searchIcon}</div>
                                <input type="text" className="form-control" placeholder="What do you want to find?" />
                            </div>

                            <div className="filters">
                                <span className="static">Include:</span>

                                <div className="checkboxes">
                                    <div className="checboxItem">
                                        <input type="checkbox" className="checkbox" id="communityFilter" />
                                        <label htmlFor="communityFilter">Community</label>
                                    </div>
                                    <div className="checboxItem">
                                        <input type="checkbox" className="checkbox" id="collectionsFilter" />
                                        <label htmlFor="collectionsFilter">Collections</label>
                                    </div>
                                    <div className="checboxItem">
                                        <input type="checkbox" className="checkbox" id="recommendationsFilter" />
                                        <label htmlFor="recommendationsFilter">Recommendations</label>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="searchResultWrapper">
                            <div className="emptySearch">
                                <div className="promptText">"Best books to read about globalisation"</div>
                                <div className="imageWrapper"><img src={require("../../assets/images/no-search-result.png")} alt="" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

              <div className="communities">
                <div className="dockSectionTitle">YOUR COMMUNITIES</div>
                {userOrgs?.map((org) => (
                  <div
                    className={`communityThumb ${
                      activeCommunityId === org.uuid ? "active" : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      // e.stopPropagation();

                      router.navigate(`/c/${org.uuid}`);
                    }}
                    key={org.uuid}
                    title={org.organisationDisplayName}
                  >
                    {/* {org?.orgIconImageURL?.length > 10 ? (
                    <img src={org?.orgIconImageURL} alt="" />
                  ) : (
                    <div className="orgImageIcon">{org?.orgIconImageURL}</div>
                  )} */}
                    <CommunityIcon orgIconImageURL={org?.orgIconImageURL} />
                    {/* <img src={require("../../assets/images/com_pic1.png")} alt="" /> */}

                    <div className="hoverInfo">
                      <div className="comTitle">
                        {org.organisationDisplayName}
                      </div>
                      <div className="comMeta">{org.membersCount} Members</div>
                    </div>
                  </div>
                ))}

                {/* <div className="communityThumb" onClick={(e) => _onClick()}>
                <img src={require("../../assets/images/com_pic2.png")} alt="" />

                            <div className="communityThumb active" onClick={(e) => _openCommunity()}>
                                <img src={require("../../assets/images/com_pic2.png")} alt="" />

                                <div className="hoverInfo">
                                    <div className="comTitle">Nomad</div>
                                    <div className="comMeta">400 Members - 200 new items this week</div>
                                </div>
                            </div>


                            <div className="communityThumb" onClick={(e) => _openCommunity()}>
                                <img src={require("../../assets/images/com_pic3.png")} alt="" />

                                <div className="hoverInfo">
                                    <div className="comTitle">Community title</div>
                                    <div className="comMeta">400 Members - 200 new items this week</div>
                                </div>

                                <div className="newIndicator"></div>
                            </div>
                        </div>
                    </div>





                    {/* ************************************************* VISIBLE ONLY IN MOBILE */}
                <div className="mobile_appearance">
                  <div className="dockSectionTitle">Appearance</div>

                  <div className="toggleItems">
                    <div className="toggleItem">
                      <span>Theme</span>
                      <div
                        className="toggleOptionWrap"
                        onClick={_onThemeChange}
                      >
                        <div
                          className={
                            "toggleOptions " + (isLightMode ? "active" : "")
                          }
                        >
                          🌞 light
                        </div>
                        <div
                          className={
                            "toggleOptions " + (isLightMode ? "" : "active")
                          }
                        >
                          🌚 dark
                        </div>
                      </div>
                    </div>

                    {/* <div className="toggleItem">
                                <span>Motion</span>
                                <div className="toggleOptionWrap" onClick={(e) => setMotionMode(!motionMode)}>
                                    <div className={"toggleOptions " + (motionMode ? "active" : "")}>Yes</div>
                                    <div className={"toggleOptions " + (motionMode ? "" : "active")}>No</div>
                                </div>
                            </div> */}
                  </div>
                </div>

                <div className="mobile_more">
                  <div className="dockSectionTitle">More</div>

                  <div className="moreItems">
                    <div
                      className="item"
                      onClick={(e) => {
                        setMorePopupOpen(false);
                        router.navigate("/c/xYyMG49YiBE/q/76700882");
                      }}
                    >
                      <div className="box">📕</div>
                      <div className="text">Release log</div>
                    </div>
                    <div
                      className="item"
                      onClick={(e) => {
                        e.stopPropagation();
                        EventEmitter.dispatch(
                          SiteWiseEvents.OPEN_FEEDBACK_MODAL_MOBILE
                        );
                      }}
                    >
                      <div className="box">📣</div>
                      <div className="text">Feedback</div>
                    </div>
                  </div>
                </div>

                <div className="mobile_extralinks">
                  <div
                    className="link"
                    onClick={(e) => {
                      // OPen in new tab
                      window.open(
                        "https://quester-io.notion.site/About-Us-3c179e713dbe40c28898e4e2d46d55f9",
                        "_blank"
                      );
                    }}
                  >
                    About us
                  </div>
                  <div
                    className="link"
                    onClick={(e) => router.navigate("/terms")}
                  >
                    Terms
                  </div>
                  <div
                    className="link"
                    onClick={(e) => router.navigate("/privacy")}
                  >
                    Privacy
                  </div>
                </div>

                <div className="mobile_sidebarBottomSticky">
                  {keycloak?.authenticated ? (
                    <div className="left" onClick={(e) => AuthHelper.logout()}>
                      Logout
                    </div>
                  ) : (
                    <>
                      <div className="left" onClick={(e) => AuthHelper.login()}>
                        Login/Signup
                      </div>
                    </>
                  )}
                  {/* <div className="left">Signup/Login</div> */}
                </div>
                {/* ************************************************* /VISIBLE ONLY IN MOBILE */}
              </div>
            </div>
          </div>

          {/* <div className={"feedbackPopup d-md-none d-grid " + (feedbackModalOpen ? "show" : "")}>
          <FeedbackModal />  
        </div> */}

          <div className="moreSection">
            <div className="dailyStreakWrapper">
              <DailyStreak />
            </div>{" "}
            <span
              className="icon feedbackIcon"
              onClick={(e) => {
                e.stopPropagation();
                EventEmitter.dispatch(SiteWiseEvents.OPEN_FEEDBACK_MODAL);
              }}
            >
              {" "}
              📣{" "}
            </span>
            <FeedbackModal />
            <span
              className={"icon " + (morePopupOpen ? "active" : "")}
              onClick={(e) => setMorePopupOpen(true)}
              onMouseEnter={(e) => moreIconRef.current.play()}
            >
              {/* {ICONS.moreHorizontal} */}
              <Player
                src={require("../../assets/animations/more-icon.lottie.json")}
                className="moreIcon"
                loop={false}
                autoplay={false}
                ref={moreIconRef}
              />
            </span>
            <OutsideClickHandler onOutsideClick={() => setMorePopupOpen(false)}>
              <div className={"morePopup " + (morePopupOpen ? "show" : "")}>
                <div className="topSection">
                  <div
                    className="item"
                    onClick={(e) => {
                      setMorePopupOpen(false);
                      router.navigate("/c/xYyMG49YiBE/q/76700882");
                    }}
                  >
                    <div className="box">📕</div>
                    <div className="text">Release log</div>
                  </div>
                  {/* <div className="item">
                  <div className="box">📣</div>
                  <div className="text">Feedback</div>
                </div> */}
                </div>

                <div className="toggleBlock">
                  <div className="title">appearance</div>

                  <div className="toggleItems">
                    <div className="toggleItem">
                      <span>Theme</span>
                      <div
                        className="toggleOptionWrap"
                        onClick={_onThemeChange}
                      >
                        <div
                          className={
                            "toggleOptions " + (isLightMode ? "active" : "")
                          }
                        >
                          🌞 light
                        </div>
                        <div
                          className={
                            "toggleOptions " + (isLightMode ? "" : "active")
                          }
                        >
                          🌚 dark
                        </div>
                      </div>
                    </div>

                    {/* <div className="toggleItem">
                                      <span>Motion</span>
                                      <div className="toggleOptionWrap" onClick={(e) => setMotionMode(!motionMode)}>
                                          <div className={"toggleOptions " + (motionMode ? "active" : "")}>Yes</div>
                                          <div className={"toggleOptions " + (motionMode ? "" : "active")}>No</div>
                                      </div>
                                  </div> */}
                  </div>
                </div>

                <div className="socialBlock">
                  <div
                    className="block"
                    onClick={(e) =>
                      _navigateTo(
                        e,
                        "https://discord.com/servers/quester-io-867461763090743327"
                      )
                    }
                  >
                    {ICONS.discordIcon}
                  </div>
                  <div
                    className="block"
                    onClick={(e) =>
                      _navigateTo(e, "https://www.instagram.com/quester.io/")
                    }
                  >
                    {ICONS.instagramIcon}
                  </div>
                  <div
                    className="block"
                    onClick={(e) =>
                      _navigateTo(
                        e,
                        "https://www.tiktok.com/@quester.io?lang=en"
                      )
                    }
                  >
                    {ICONS.tiktokIcon}
                  </div>
                  <div
                    className="block"
                    onClick={(e) =>
                      _navigateTo(
                        e,
                        "https://www.linkedin.com/company/quester-io/"
                      )
                    }
                  >
                    {ICONS.linkedinIcon}
                  </div>
                </div>

                <div className="extraLinksBlock">
                  <div
                    className="link"
                    onClick={(e) => {
                      // OPen in new tab
                      window.open(
                        "https://quester-io.notion.site/About-Us-3c179e713dbe40c28898e4e2d46d55f9",
                        "_blank"
                      );
                    }}
                  >
                    About us
                  </div>
                  <div
                    className="link"
                    onClick={(e) => router.navigate("/terms")}
                  >
                    Terms
                  </div>
                  <div
                    className="link"
                    onClick={(e) => router.navigate("/privacy")}
                  >
                    Privacy
                  </div>
                </div>
                {keycloak?.authenticated ? (
                  <div
                    className="logOutBlock"
                    onClick={(e) => AuthHelper.logout()}
                  >
                    <div className="logoutButton">Logout</div>
                  </div>
                ) : (
                  <>
                    <div
                      className="logOutBlock"
                      onClick={(e) => AuthHelper.login()}
                    >
                      <div className="logoutButton">Login/Signup</div>
                    </div>
                  </>
                )}
              </div>
            </OutsideClickHandler>
          </div>
        </div>
      </div>
      <Notifications showMobile={true} />
    </>
  );
};

export default SideBar;
