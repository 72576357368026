import ActivityRecorder from "..";
import GtagEventLogger from "../../gtag-event-logger";

/**
     activityType: "add-ai-assisted-resource",
    orgId: "orgId",
    orgName: "orgName",
    questId: "questId",
    questName: "questName",
    value1: "url",
    value2: ["topic1", "topic2"],
    value3: "type",
    value4: "good/bad/no-response",
    value6: "published/unpublished",
 */

const AIActivities = {
  addAIAssistedResource: ({
    link,
    aiAssistedTopics,
    aiAssistedType,
    response,
    publishStatus,
    resourceId = "",
  }) => {
    const metaData = ActivityRecorder.generateActivityMetaData();
    const activity = {
      entityType: "community-resource",
      entityId: resourceId,
      entityValue1: link,
      entityValue2: aiAssistedTopics,
      entityValue3: aiAssistedType,
      entityValue4: response,
      entityValue5: publishStatus,
      activityType: `add-ai-assisted-resource`,
      createdOn: +new Date(),
      ...metaData,
    };
    ActivityRecorder.session.activities.push(activity);
    setTimeout(() => {
      ActivityRecorder.writeToDB();
      GtagEventLogger.logEvent(`add-ai-assisted-resource`, activity);
    }, 200);
  },
};

export default AIActivities;
