import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import router from "./shared/top-level-declarations/history";
import { PostHogProvider } from "posthog-js/react";
import posthog from "posthog-js";

posthog.init(window.REACT_APP_PUBLIC_POSTHOG_KEY, {
  api_host: window.REACT_APP_PUBLIC_POSTHOG_HOST,
  loaded: function (posthog) {
    // console.log('posthog :>> ', posthog.get_session_replay_url());
  },
  autocapture: true,
  disable_session_recording: false,
  capture_pageview: true,
  capture_links_timeout: 2000000,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <PostHogProvider client={posthog}>
    <RouterProvider router={router} />
  </PostHogProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
