import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ICONS from "../../assets/quester-icons";
import QuestComments from "../../features/quest-comments/components/quest-comments/quest-comments";
import AuthHelper from "../../shared/helper-methods/auth-helper";
import useKeyCloakSafe from "../../shared/hooks/useSafeKeycloak";
import router from "../../shared/top-level-declarations/history";
import DiscoveryBar from "../discover-bar/discovery-bar";
import MobileDrawer from "../mobile-drawer/mobile-drawer";
import CommunityComments from "../../features/quest-comments/components/community-comments/community-comments";
import FeedbackModal from "../../features/feedback-collector/feedback-modal/feedback-modal";
import AddToCollectionPopup from "../add-to-collection-popup/add-to-collection-popup";
import AddContentPopup from "../add-content-popup/add-content-popup";
import AddItemFlow from "../add-item-flow/add-item-flow";
import AddNewCollection from "../add-new-collection/add-new-collection";
import Button from "../buttons/buttons";
import "./mobile-footer.scss";
import ResourceWizard from "../../entities/resource/components/resource-wizard/resource-wizard";
import useDeepCompareEffect from "use-deep-compare-effect";
import CommunityUtils from "../../entities/community/community-utils";
import { useSelector } from "react-redux";

const MobileFooter = (props) => {
  const [showDiscoveryDrawer, setShowDiscoveryDrawer] = useState(false);
  const [showCommentsDrawer, setShowCommentsDrawer] = useState(false);
  const { keycloak, initialized } = useKeyCloakSafe();
  const location = useLocation();
  const [buttonVisibilities, setButtonVisibilities] = useState({
    login: false,
    discover: false,
    communityComments: false,
    collectionComments: false,
    addResource: false,
  });
  const { activeCommunity } = useSelector((state) => state.communityStore);
  const [curationAccess, setCurationAccess] = useState(false);

  const _showMobileSidebar = (e) => {
    // console.log('show mobile sidebar');
    document.body.classList.add("sidebarShow");
  };

  const _generateButtonVisibilities = () => {
    let buttonVisibilities = {
      login: false,
      discover: false,
      comments: false,
    };

    if (initialized) {
      if (!keycloak?.authenticated) {
        buttonVisibilities.login = true;
      }
    }

    const path = location.pathname;
    if (path.includes("/c/")) {
      buttonVisibilities.discover = true;
      buttonVisibilities.communityComments = true;
      if (curationAccess) {
        buttonVisibilities.addResource = true;
      }
    }

    if (path.includes("/q/")) {
      buttonVisibilities.communityComments = false;
      buttonVisibilities.collectionComments = true;
      buttonVisibilities.addResource = false;
    }

    // Open the comments drawer if it has params '?replyCommentId='
    if (path.includes("/q/") && location.search.includes("?replyCommentId=")) {
      setTimeout(() => {
        setShowCommentsDrawer(true);
      }, 900);
    }

    setButtonVisibilities(buttonVisibilities);
  };

  useEffect(() => {
    _generateButtonVisibilities();
  }, [location, keycloak, initialized]);

  useDeepCompareEffect(() => {
    const hasCurationAccess = CommunityUtils.checkIfHasCurationAccess();
    setCurationAccess(hasCurationAccess);
    if (hasCurationAccess) {
      setButtonVisibilities((prev) => ({
        ...prev,
        addResource: true,
      }));
    }
  }, [activeCommunity || {}]);

  const [headerClassName, setHeaderClassName] = useState("");

  const handleScroll = (headerClassName) => {
    if (headerClassName !== "menuscroll" && window.pageYOffset >= 100) {
      setHeaderClassName("menuscroll");
    } else if (headerClassName === "menuscroll" && window.pageYOffset < 100) {
      setHeaderClassName("");
    }
  };

  useEffect(() => {
    window.onscroll = () => handleScroll(headerClassName);
  }, [headerClassName]); // IMPORTANT, This will cause react to update depending on change of this value

  const [feedbackSheetShow, setFeedbackSheetShow] = useState(true);

  const [showAddToCollectionPopup, setShowAddToCollectionPopup] =
    useState(true);
  const [showAddContentPopup, setShowAddContentPopup] = useState(true);
  const [showAddNewCollectionPopup, setShowAddNewCollectionPopup] =
    useState(true);
  const [showAddItemFlowPopup, setShowAddItemFlowPopup] = useState(true);
  const [showSaveDraftConfirmation, setShowSaveDraftConfirmation] =
    useState(true);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(true);

  return (
    <>
      <div className={"mobileFooter " + headerClassName}>
        <div className="left" onClick={_showMobileSidebar}>
          <div className="menuToggle">
            {ICONS.threeBars} <span>Dashboard</span>
          </div>
        </div>

        <div className="right">
          {/* <div className="item">{ICONS.userCircle} <span>Login</span></div> */}
          {buttonVisibilities.login ? (
            <div className="item" onClick={(e) => AuthHelper.login()}>
              {ICONS.userCircle} <span>Login</span>
            </div>
          ) : (
            <></>
          )}
          {buttonVisibilities.discover ? (
            <div className="item" onClick={(e) => setShowDiscoveryDrawer(true)}>
              {ICONS.compassIcon} <span>Discover</span>
            </div>
          ) : (
            <></>
          )}
          {buttonVisibilities.collectionComments ||
          buttonVisibilities.communityComments ? (
            <div className="item" onClick={(e) => setShowCommentsDrawer(true)}>
              {ICONS.usersCommunity} <span>Hangout</span>
            </div>
          ) : (
            <></>
          )}
          {buttonVisibilities.addResource ? (
            <div
              className="item"
              onClick={(e) => {
                CommunityUtils.showCommunityAddResourceWizard();
              }}
            >
              {ICONS.plus} <span>Resource</span>
            </div>
          ) : (
            <></>
          )}
          {!buttonVisibilities.discover &&
          !buttonVisibilities.comments &&
          !buttonVisibilities.login ? (
            <div
              className="item"
              onClick={(e) => {
                router.navigate("/my-profile");
              }}
            >
              {ICONS.userCircle} <span>My profile</span>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      <MobileDrawer
        isActive={showDiscoveryDrawer}
        onClose={(e) => setShowDiscoveryDrawer(false)}
        headerTitle="Discover"
        headerIcon={ICONS.compassIcon}
        extraClass="discoverDrawer"
      >
        <DiscoveryBar
          open={true}
          closeParentDrawer={() => setShowDiscoveryDrawer(false)}
          withinDrawer={true}
        />
      </MobileDrawer>

      <MobileDrawer
        isActive={showCommentsDrawer}
        onClose={(e) => setShowCommentsDrawer(false)}
        headerTitle="Hangout"
        headerIcon={ICONS.usersCommunity}
        extraClass="commentsDrawer"
      >
        <QuestComments />
      </MobileDrawer>

      {/* <MobileDrawer
        isActive={showAddToCollectionPopup}
        onClose={(e) => setShowAddToCollectionPopup(false)}
        headerTitle="add to collection"
        headerIcon={ICONS.plus}
        extraClass="addToCollectionDrawer"
      >
        <AddToCollectionPopup />
      </MobileDrawer> */}

      {/* <MobileDrawer
        isActive={showAddContentPopup}
        onClose={(e) => setShowAddContentPopup(false)}
        headerTitle="Add content"
        headerIcon={ICONS.plus}
        extraClass="addContentDrawer"
      >
        <AddContentPopup /> 
      </MobileDrawer> */}

      <MobileDrawer
        isActive={false}
        onClose={(e) => setShowAddNewCollectionPopup(false)}
        headerTitle="creating a collection"
        headerIcon={ICONS.plus}
        extraClass="addNewCollectionsDrawer"
      >
        <div className="addNewCollectionWrapper">
          <div className="inner">
            <div className="section editCollectionSection">
              <div className="topHeading">EDITING COLLECTION</div>

              <div className="editInputBoxes">
                <div className="imageWrapper">
                  <img src="https://placehold.co/600x400" alt="" />
                  {ICONS.imageIcon}
                </div>

                <div className="right">
                  <div className="inputGroup">
                    <label htmlFor="">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Title of collection"
                    />
                  </div>
                  <div className="inputGroup">
                    <label htmlFor="">Description</label>
                    <textarea
                      name=""
                      rows="2"
                      className="form-control"
                      placeholder="Something to describe this collection"
                    >
                      We gather resources to pass IB exams. All resources you
                      will find here are free. df dd We gather resources to pass
                      IB exams. All resources you will find here are free. df dd
                    </textarea>
                  </div>

                  <div className="buttons">
                    <Button
                      size={"extraSmall"}
                      type={"danger"}
                      icon={ICONS.corssCircle}
                      iconPos={"left"}
                      text={"Cancel"}
                    />
                    <Button
                      size={"extraSmall"}
                      type={"success"}
                      icon={ICONS.checkCircle}
                      iconPos={"left"}
                      text={"Confirm changes"}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="section editCollectionSection viewMode">
              <div className="topHeading">EDITING COLLECTION</div>

              <div className="editInputBoxes">
                <div className="imageWrapper">
                  <img src="https://placehold.co/600x400" alt="" />
                  {/* {ICONS.imageIcon} */}
                </div>

                <div className="right">
                  <div className="inputGroup">
                    <label htmlFor="">Title</label>
                    <div className="title">Title of collection</div>
                  </div>
                  <div className="inputGroup">
                    <label htmlFor="">Description</label>
                    <div className="desc">
                      We gather resources to pass IB exams. All resources you
                      will find here are free. df dd We gather resources to pass
                      IB exams. All resources you will find here are free. df dd
                    </div>
                  </div>

                  <div className="buttons">
                    <Button
                      size={"extraSmall"}
                      type={"default"}
                      icon={ICONS.edit}
                      iconPos={"left"}
                      text={"Edit"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="createCollectionButtonWrapper">
            <Button
              size={"small"}
              type={"contrast"}
              text={"Create collection"}
              extraClasses={"createCollectionButton"}
            />
          </div>
        </div>
      </MobileDrawer>

      <ResourceWizard isMobile={true} />

      {/* <MobileDrawer
        isActive={showSaveDraftConfirmation}
        onClose={(e) => setShowSaveDraftConfirmation(!showSaveDraftConfirmation)}
        headerTitle="creating a collection"
        headerIcon={ICONS.list2}
        extraClass="confirmationDrawer"
      >
        <div className="confirmationSheetInner">
          <div className="confirmationHeaderTitle">Do you want to save it as draft?</div>
          <div className="confirmationDesc">Quo repellat alias. Et qui sit quo at omnis rerum ex corporis consectetur. Sed ducimus corrupti ut sapiente atque doloremque eos consectetur assumenda.</div>
          <div className="confirmationButtons">
              <Button
                size={"small"}
                type={"contrast"}
                text={"No, thanks!"}
              />
              <Button
                size={"small"}
                type={"success"}
                text={"Save as draft"}
              />
          </div>
        </div>
      </MobileDrawer>

  



      <MobileDrawer
        isActive={showDeleteConfirmation}
        onClose={(e) => setShowDeleteConfirmation(!showDeleteConfirmation)}
        headerTitle="deleting a collection"
        headerIcon={ICONS.list2}
        extraClass="confirmationDrawer"
      >
        <div className="confirmationSheetInner">
          <div className="confirmationHeaderTitle">Do you want to delete this collection?</div>
          <div className="confirmationDesc">Quo repellat alias. Et qui sit quo at omnis rerum ex corporis consectetur. Sed ducimus corrupti ut sapiente atque doloremque eos consectetur assumenda.</div>
          <div className="confirmationButtons">
              <Button
                size={"small"}
                type={"contrast"}
                text={"No, thanks!"}
              />
              <Button
                size={"small"}
                type={"danger"}
                text={"Delete"}
              />
          </div>
        </div>
      </MobileDrawer> */}

      {/* <MobileDrawer
        isActive={feedbackSheetShow}
        onClose={(e) => setFeedbackSheetShow(false)}
        headerTitle="Feedback"
        headerIcon={ICONS.megaPhone}
        extraClass="feedbackDrawer"
      >
        <FeedbackModal />
      </MobileDrawer> */}
    </>
  );
};

export default MobileFooter;
