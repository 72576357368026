import cloneDeep from "clone-deep";
import commentStoreActionTypes from "./action-types";

const initialState = {
  allComments: [],
  isLoaderActive: false,
  isRootView: true,
  nestedLevelRootAddress: "",
  activeNestedComments: [],
  isExpanded: true,
  isReferenceView: false,
  referenceQuestItemId: "",
  referenceViewComments: [],
  pagination: {
    currentPage: 0,
    pageSize: 10,
    canLoadMore: true,
  },
};

export const commentStoreReducer = (
  state = cloneDeep(initialState),
  action
) => {
  let newState = { ...state };
  switch (action.type) {
    case commentStoreActionTypes.TOGGLE_LOADER: {
      newState = {
        ...newState,
        isLoaderActive: action.payload,
      };
      break;
    }
    case commentStoreActionTypes.SET_REFERENCE_QUESTITEMID: {
      newState = {
        ...newState,
        referenceQuestItemId: action.payload,
      };
      break;
    }
    case commentStoreActionTypes.SET_ALL_COMMENTS: {
      newState = {
        ...newState,
        allComments: action.payload,
      };
      break;
    }
    case commentStoreActionTypes.SET_ACTIVE_NESTED_COMMENTS: {
      newState = {
        ...newState,
        activeNestedComments: action.payload,
      };
      break;
    }
    case commentStoreActionTypes.SET_ROOT_VIEW: {
      newState = {
        ...newState,
        isRootView: action.payload,
      };
      break;
    }
    case commentStoreActionTypes.SET_NESTED_LEVEL_ROOT_ADDRESS: {
      newState = {
        ...newState,
        nestedLevelRootAddress: action.payload,
      };
      break;
    }
    case commentStoreActionTypes.SET_EXPANDED: {
      newState = {
        ...newState,
        isExpanded: action.payload,
      };
      break;
    }
    case commentStoreActionTypes.SET_REFERENCE_VIEW: {
      newState = {
        ...newState,
        isReferenceView: action.payload,
      };
      break;
    }
    case commentStoreActionTypes.SET_REFERENCE_VIEW_COMMENTS: {
      newState = {
        ...newState,
        referenceViewComments: action.payload,
      };
      break;
    }
    case commentStoreActionTypes.SET_PAGINATION: {
      console.log("00009 SET_PAGINATION", action);
      newState = {
        ...newState,
        pagination: action.payload,
      };
      break;
    }
    case commentStoreActionTypes.CLEAR_PAGINATION: {
      
      newState = {
        ...newState,
        pagination: cloneDeep(initialState.pagination),
      };
      break;
    }
    case commentStoreActionTypes.CLEAR_STORE: {
      newState = cloneDeep(initialState);
      break;
    }

    default: {
    }
  }
  return newState;
};
