import cloneDeep from "clone-deep";

import CollectionStoreActionTypes from "./collection-store-action-types";

const initialState = {
  activeCollection: {},
  activeCollectionId: null,
  activeCollectionItems: [],
  isCollectionLoaderActive: true,
  isCollectionItemsLoaderActive: true,
};

export const CollectionStoreReducer = (
  state = cloneDeep(initialState),
  action
) => {
  let newState = { ...state };
  switch (action.type) {
    case CollectionStoreActionTypes.SET_ACTIVE_COLLECTION: {
      newState = {
        ...newState,
        activeCollection: action.payload,
      };
      break;
    }
    case CollectionStoreActionTypes.SET_ACTIVE_COLLECTION_ID: {
      newState = {
        ...newState,
        activeCollectionId: action.payload,
      };
      break;
    }
    case CollectionStoreActionTypes.SET_ACTIVE_COLLECTION_ITEMS: {
      newState = {
        ...newState,
        activeCollectionItems: action.payload,
      };
      break;
    }
    case CollectionStoreActionTypes.TOGGLE_COLLECTION_LOADER_STATUS: {
      newState = {
        ...newState,
        isCollectionLoaderActive: action.payload,
      };
      break;
    }
    case CollectionStoreActionTypes.TOGGLE_COLLECTION_ITEMS_LOADER_STATUS: {
      newState = {
        ...newState,
        isCollectionItemsLoaderActive: action.payload,
      };
      break;
    }
    case CollectionStoreActionTypes.CLEAR_COLLECTION_DATA: {
      newState = cloneDeep(initialState);
      break;
    }
    default: {
    }
  }
  return newState;
};
