import React, { useEffect, useState } from "react";
import ICONS from "../../../../assets/quester-icons";
import Button from "../../../../components/buttons/buttons";
import { useSelector } from "react-redux";
import router from "../../../../shared/top-level-declarations/history";
import { useParams } from "react-router-dom";
import CollectionUtils from "../../collection-utils";
import CollectionIcon from "../collection-icon/collection-icon";
import GeneralHelper from "../../../../shared/helper-methods/general-helpers";
import UserImageRenderer from "../../../../components/user-image-renderer/user-image-renderer";
import useDeepCompareEffect from "use-deep-compare-effect";
import SaveButton from "../../../../components/save-button/save-button";

const CollectionInfoViewer = () => {
  const [expandDesc, setExpandDesc] = useState(false);
  const [curators, setCurators] = useState([]);
  const { activeCollection, activeCollectionItems } = useSelector(
    (state) => state.collectionStore
  );

  let { communityId, questId } = useParams();

  const _toggleFollow = () => {
    CollectionUtils.toggleFollow(activeCollection);
  };

  useDeepCompareEffect(() => {
    let curators = [];
    if (activeCollection?.ownerId?.length) {
      curators.push({
        name: activeCollection?.creatorName?.length
          ? activeCollection?.creatorName
          : activeCollection?.ownerId,
        photoUrl: activeCollection?.creatorPhoto,
        ownerId: activeCollection?.ownerId,
      });
    }
    if (activeCollectionItems.length) {
      activeCollectionItems.forEach((item) => {
        if (item.ownerId === activeCollection?.ownerId) return;
        curators.push({
          name: item.displayName?.length ? item.displayName : item.ownerId,
          photoUrl: item.photoUrl,
          ownerId: item.ownerId,
        });
      });
      // Now remove duplicates
      curators = curators.filter(
        (curator, index, self) =>
          index === self.findIndex((t) => t.ownerId === curator.ownerId)
      );
      setCurators(curators);
    }
  }, [activeCollection, activeCollectionItems]);

  const shouldShowExpanBtn = GeneralHelper.checkIfMobile()
    ? activeCollection?.description?.length > 200
    : activeCollection?.description?.length > 300;





  return (
    <>
      <div className="collectionDetailsWrapper">
        <div className="inner">
          <div className="detailWrapper">
            <div className="d-inline-block d-md-none">
              <span className="collectionTag ">
                <div className="innerTag">
                  {/* {ICONS.diamondGradient} */}
                  <b>Collection</b>
                </div>
              </span>
            </div>
            
            <div className="imageWrapper">
              <CollectionIcon
                collectionImageLink={activeCollection?.questImage}
              />
            </div>

            <div className="descWrapper">
              <div className="topHeader">
                <div className="left">
                  {/* <div className="collectionTag">Collection</div> */}
                  {
                    activeCollection?.isPremium ? (
                      <div className="collectionTag premiumCollectionTag">{ICONS.diamondGradient} <span>Collection</span></div>
                    ): <> </>
                  }
                  <div className="collectionTitle">
                    {activeCollection?.listname}
                  </div>
                </div>
                <div className="buttons">
                  <SaveButton
                    saveCount={activeCollection?.followersCount}
                    alreadySaved={activeCollection?.isFollowed}
                    onToggle={_toggleFollow}
                    hideCount={false}
                  />
                  <Button
                    size={"extraSmall"}
                    icon={ICONS.share}
                    iconPos={"left"}
                    type={"contrast"}
                    text="Share"
                    onClick={CollectionUtils.shareCollection}
                  />
                </div>
              </div>

              <div className="collectionMeta">
                <div className="top">
                  {/* <span className="pointer-events-all">
                    CREATED BY SERGIO and 4 colLaborators
                  </span> */}

                  <div className="d-none d-md-inline-flex">
                    <span className="collectionTag "> {/* add premium class */}
                      <div className="innerTag">
                        {/* {ICONS.diamondGradient} */}
                        <b>Collection</b>
                      </div>
                    </span>
                  </div>

                  <span>·</span>

                  <span className="pointer-events-all">
                    CREATED BY {curators.length ? curators[0].name : ""}
                    <>
                      {curators.length > 1 ? (
                        <> and {curators.length - 1} colLaborators</>
                      ) : (
                        <> </>
                      )}
                    </>
                  </span>

                  {activeCollectionItems.length ? (
                    <>
                      <span>·</span>
                      <span>{activeCollectionItems.length} ITEMS</span>
                    </>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="infoPopup">
                  <div className="popupIinner">
                    <div className="popupHeader">list of collaborators</div>

                    <div className="popupContent">
                      {curators?.length ? (
                        <div className="peopleSection">
                          <div className="label">Creator</div>
                          <div className="users">
                            <div className="bigUserBadge">
                              <div
                                className="bigImageWrapper"
                                title={curators[0].name}
                              >
                                <UserImageRenderer
                                  userName={curators[0].ownerId}
                                  photoUrl={curators[0].photoUrl}
                                />
                              </div>
                              <div className="userid">{curators[0].name}</div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {curators?.length > 1 ? (
                        <>
                          <div className="peopleSection">
                            <div className="label">Collaborators</div>
                            <div className="users">
                              {curators?.map((curator, index) => {
                                if (index >= 1) {
                                  return (
                                    <div
                                      className="smallUserBadge"
                                      key={index}
                                      title={curator.name}
                                    >
                                      
                                        <UserImageRenderer
                                          userName={curator.ownerId}
                                          photoUrl={curator.photoUrl}
                                        />
                                      
                                      {/* <div className="userid">{curator.name}</div> */}
                                    </div>
                                  );
                                }
                                return <></>;
                              })}
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="collectionDesc">
                <div className={"texts " + (expandDesc ? "showFull" : "")}>
                  {activeCollection?.description}
                </div>
                <div className="seeMoreToggleWrapper">
                  {shouldShowExpanBtn ? (
                    <>
                      <div
                        className="seeMoreToggle"
                        onClick={(e) => setExpandDesc(!expandDesc)}
                      >
                        {expandDesc ? "See less" : "See more"}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            className="closeWrapper"
            onClick={(e) => {
              CollectionUtils.clearCollectionData();
              router.navigate(`/c/${communityId}`);
            }}
          >
            <div className="icon">{ICONS.crossIcon}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CollectionInfoViewer;
