/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import ICONS from "../../../../assets/quester-icons";
import SiteWiseEvents from "../../../../declarations/site-wise-events";
import GeneralHelper from "../../../../shared/helper-methods/general-helpers";
import { EventEmitter } from "../../../../shared/utils/event-emitter";
import SponsorComment from "../../../sponsorship/components/sponsor-comment/sponsor-comment";
import QuestCommentsHelper from "../../quest-comments-helper";
import CommentComposer from "../comment-composer/comment-composer";
import QuestComment from "../quest-comment/quest-comment";
import "./quest-comments.scss";

const QuestComments = () => {
  const { showCommentViewer, hideCommentViewer } = QuestCommentsHelper;
  const [isRendered, setIsRendered] = useState(false);
  const [commentCounts, setCommentCounts] = useState(0);

  const location = useLocation();
  const params = useParams();

  const {
    isExpanded,
    allComments,
    activeNestedComments,
    isRootView,
    isReferenceView,
    referenceViewComments,
    isLoaderActive,
  } = useSelector((state) => state.commentStore || {});

  const _diactiveReferenceView = () => {
    QuestCommentsHelper.deactivateReferenceMode();
  };

  const commentsWrapperRef = useRef(null);
  const commentsInnerWrapperRef = useRef(null);

  useEffect(() => {
    // If any of this changes, then we need to scroll to the top of this component
    if (isRendered) {
      const isMobile = GeneralHelper.checkIfMobile();
      if (isMobile && commentsWrapperRef.current) {
        commentsWrapperRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
          top: -100,
        });
      }
    }
  }, [isRootView, isReferenceView]);

  useEffect(() => {
    setTimeout(() => {
      setIsRendered(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (allComments?.length > 0) {
      const commentCount =
        QuestCommentsHelper.countCommentsAndAnyLevelReplies();
      setCommentCounts(commentCount);
    }
  }, [allComments]);

  const _onCollapse = () => {
    if (commentsInnerWrapperRef.current) {
      // Scroll to the top of the comments
      commentsInnerWrapperRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      hideCommentViewer();
    }
  };

  // On route param change, we need to reset the comment viewer
  useEffect(() => {
    if (!isLoaderActive && allComments.length > 0) {
      const replyCommentId = new URLSearchParams(location.search).get(
        "replyCommentId"
      );
      // Call your function here with the new replyCommentId
      if (replyCommentId) {
        // Expand the comment viewer
        showCommentViewer();
      }
      EventEmitter.dispatch(SiteWiseEvents.openCommentBar);

      // You can also access other params directly from the `params` object
      // Example: params.yourParamName
    }
  }, [location, params, isLoaderActive, allComments.length]);

  const _toggleCommentBar = () => {
    if (isExpanded) {
      hideCommentViewer();
    } else {
      showCommentViewer();
    }
  };

  const _registerRequiredEventListners = () => {
    EventEmitter.subscribe(SiteWiseEvents.openDiscoveryBar, () => {
      // Check if screen width is less than 1200px
      if (window.innerWidth < 1200) {
        hideCommentViewer();
      }
    });
  };

  useEffect(() => {
    _registerRequiredEventListners();
  }, []);

  return (
    <div className={"commentBar " + (isExpanded ? "open" : "close")}>
      <div className="commentBarInner">
        <div className="commentBarHeader">
          <div
            className="icon"
            title={isExpanded ? "Collapse comment" : "Expand comment"}
            onClick={_toggleCommentBar}
          >
            {ICONS.usersCommunity}
            {!allComments?.length ? "" : <div className="newIndicator"></div>}
          </div>
          <div className="title">Hangout</div>
        </div>

        <div className="commentBarContent">
          <CommentComposer isTopLevel={true} parentCommentId={null} />

          {isReferenceView ? (
            <div className="prevComments" onClick={_diactiveReferenceView}>
              {ICONS.arrowLeft} Back to thread
            </div>
          ) : (
            <></>
          )}

          <div className="comments">
            <SponsorComment />
            {/* COMMENT THREAD START */}
            {isReferenceView ? (
              <>
                {referenceViewComments?.map((comment) => (
                  <QuestComment
                    comment={comment}
                    key={comment.id}
                    isReferenceView={true}
                  />
                ))}
              </>
            ) : (
              <>
                {isRootView ? (
                  <>
                    {allComments?.map((comment) => (
                      <QuestComment
                        comment={comment}
                        key={comment.id}
                        isReferenceView={false}
                      />
                    ))}
                  </>
                ) : (
                  <>
                    {activeNestedComments?.map((comment) => (
                      <QuestComment
                        comment={comment}
                        key={comment.id}
                        isReferenceView={false}
                      />
                    ))}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestComments;
