import React, { Component } from "react";
import CollectionCard from "../collection-card/collection-card";
import { useSelector } from "react-redux";
import useCommunityStats from "../../../../shared/hooks/useCommunityStats";

const CollectionsGridViewer = () => {
  const { activeCommunityCollections } = useSelector(
    (state) => state.communityStore
  );
  const { totalCollections } = useCommunityStats();

  return (
    <>
      <div className="collectionCardGridWrapper">
        <div className="sectionHeader">
          <div className="title">Collections ({totalCollections})</div>
        </div>

        <div className="collectionCardGrid">
          {activeCommunityCollections?.map((collection, index) => {
            return <CollectionCard key={index} collection={collection} />;
          })}
        </div>
      </div>
    </>
  );
};

export default CollectionsGridViewer;
