import CollectionStoreActionTypes from "./collection-store-action-types";

export const CollectionStoreActions = {
  staticActions: {
    setActiveCollection: (collection) => ({
      type: CollectionStoreActionTypes.SET_ACTIVE_COLLECTION,
      payload: collection,
    }),
    setActiveCollectionId: (collectionId) => ({
      type: CollectionStoreActionTypes.SET_ACTIVE_COLLECTION_ID,
      payload: collectionId,
    }),
    setActiveCollectionItems: (items) => ({
      type: CollectionStoreActionTypes.SET_ACTIVE_COLLECTION_ITEMS,
      payload: items,
    }),
    toggleCollectionLoaderStatus: (status) => ({
      type: CollectionStoreActionTypes.TOGGLE_COLLECTION_LOADER_STATUS,
      payload: status,
    }),
    toggleCollectionItemsLoaderStatus: (status) => ({
      type: CollectionStoreActionTypes.TOGGLE_COLLECTION_ITEMS_LOADER_STATUS,
      payload: status,
    }),
    clearCollectionData: () => ({
      type: CollectionStoreActionTypes.CLEAR_COLLECTION_DATA,
    }),
  },
};
