import React, { useState } from "react";
import Button from "../../../../components/buttons/buttons";
import ICONS from "../../../../assets/quester-icons";
import { useSelector } from "react-redux";
import useDeepCompareEffect from "use-deep-compare-effect";
import ResourceUtils from "../../resource-utils";
import ResourceTopic from "../resource-topic/resource-topic";
import CommunityUtils from "../../../community/community-utils";
import OutsideClickHandler from "react-outside-click-handler";

const ResourceTopicSelector = ({
  isVisible = true,
  onDismiss = () => {},
  onSave = () => {},
  selectedTopics = [],
  isMobile = false,
}) => {
  const [newTopicName, setNewTopicName] = useState("");
  const [selectedIdMappedTopics, setSelectedIdMappedTopics] = useState({});
  const [localAllTopics, setLocalAllTopics] = useState([]);
  const [localSelectedTopics, setLocalSelectedTopics] =
    useState(selectedTopics);
  const [showNewTopicInput, setShowNewTopicInput] = useState(false);

  const { activeCommunityLabels, activeCommunityId } = useSelector(
    (state) => state.communityStore
  );

  const _updateTopicSelection = (topic) => {
    let updatedTopics = [];
    if (selectedIdMappedTopics[topic?.uuid]) {
      updatedTopics = localSelectedTopics.filter(
        (t) => t?.uuid !== topic?.uuid
      );
      setLocalSelectedTopics(updatedTopics);
    } else {
      updatedTopics = [...localSelectedTopics];
      updatedTopics.push(topic);
      setLocalSelectedTopics(updatedTopics);
    }
    if (isMobile) {
      ResourceUtils.setTopics(updatedTopics);
    }
  };

  const _submitTopicSelection = () => {
    if (showNewTopicInput) {
      return;
    }
    ResourceUtils.setTopics(localSelectedTopics);
    onDismiss();
  };

  const _confirmNewType = () => {
    // Check if the new type name is not empty
    if (!newTopicName?.length) {
      _resetNewTypeInput();
      return;
    }
    // Check if the new type name is already in the list
    const isNewTopicExist = ResourceUtils.checkIfTopicExists(newTopicName);
    if (isNewTopicExist) {
      _resetNewTypeInput();
      const topic = CommunityUtils.getTopicByName(newTopicName);
      // Check if the new type name is already added
      const isLocallyAdded = localSelectedTopics.find(
        (t) => t?.displayName === newTopicName
      );
      if (isLocallyAdded) {
        return;
      } else {
        // Add the existing topic
        _updateTopicSelection(topic);
        return;
      }
    } else {
      // Create a new topic
      const newTopic = ResourceUtils.prepareNewTopic(
        newTopicName,
        activeCommunityId
      );
      // Add the new topic
      const _allLocalTopics = [...localAllTopics];
      _allLocalTopics.push(newTopic);
      setLocalAllTopics(_allLocalTopics);
      const updatedTopics = [...localSelectedTopics];
      updatedTopics.push(newTopic);
      setLocalSelectedTopics(updatedTopics);
      if (isMobile) {
        ResourceUtils.setTopics(updatedTopics);
      }
      _resetNewTypeInput();
    }
  };

  const _resetNewTypeInput = () => {
    setNewTopicName("");
    setShowNewTopicInput(false);
  };

  useDeepCompareEffect(() => {
    setLocalSelectedTopics(selectedTopics);
  }, [selectedTopics || []]);

  useDeepCompareEffect(() => {
    const selectedTopicsIdMap = {};
    localSelectedTopics?.forEach((topic) => {
      selectedTopicsIdMap[topic?.uuid] = topic;
    });
    setSelectedIdMappedTopics(selectedTopicsIdMap);
  }, [localSelectedTopics]);

  useDeepCompareEffect(() => {
    setLocalAllTopics(activeCommunityLabels);
  }, [activeCommunityLabels || []]);

  if (isMobile) {
    return (
      <div
        className={`mobileTopicSelector ${
          isVisible ? "d-md-none" : "d-none"
        }  `}
      >
        <div className="topics">
          {localAllTopics?.map((label) => (
            <ResourceTopic
              topicTitle={label?.displayName}
              isSelected={selectedIdMappedTopics[label?.uuid]}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                _updateTopicSelection(label);
              }}
            />
          ))}

          {showNewTopicInput ? (
            <>
              <div className="newType">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Write a name"
                  value={newTopicName}
                  onChange={(e) => setNewTopicName(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      _confirmNewType();
                    }
                  }}
                />
                <div
                  className="remove"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  {" "}
                  {ICONS.crossIcon}{" "}
                </div>
              </div>
              <Button
                icon={ICONS.checkCircle}
                iconPos={"left"}
                size={"extraSmall"}
                type={"success"}
                text="Confirm"
                onClick={_confirmNewType}
              />
            </>
          ) : (
            <Button
              icon={ICONS.plus}
              iconPos={"left"}
              size={"extraSmall"}
              type={"contrast"}
              text="Add new"
              onClick={(e) => setShowNewTopicInput(true)}
            />
          )}
        </div>
      </div>
    );
  } else {
    return (
      <>
        <OutsideClickHandler onOutsideClick={onDismiss}>
          <div className={"topicSelectorPopup " + (isVisible ? "show" : "")}>
            <div className="topicSelectorInner">
              <div className="title">Topics</div>
              <div className="subtitle">Select as many as you need</div>

              <div className="topics">
                {localAllTopics?.map((label) => (
                  <ResourceTopic
                    topicTitle={label?.displayName}
                    isSelected={selectedIdMappedTopics[label?.uuid]}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      _updateTopicSelection(label);
                    }}
                  />
                ))}

                {showNewTopicInput ? (
                  <>
                    <div className="newType">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Write a name"
                        value={newTopicName}
                        onChange={(e) => setNewTopicName(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            _confirmNewType();
                          }
                        }}
                      />
                      <div
                        className="remove"
                        onClick={(e) => setShowNewTopicInput(false)}
                      >
                        {" "}
                        {ICONS.crossIcon}{" "}
                      </div>
                    </div>
                    <Button
                      icon={ICONS.checkCircle}
                      iconPos={"left"}
                      size={"extraSmall"}
                      type={"success"}
                      text="Confirm"
                      onClick={_confirmNewType}
                    />
                  </>
                ) : (
                  <Button
                    icon={ICONS.plus}
                    iconPos={"left"}
                    size={"extraSmall"}
                    type={"contrast"}
                    text="Add new"
                    onClick={(e) => setShowNewTopicInput(true)}
                  />
                )}
              </div>

              <div className="buttons">
                <Button
                  icon={ICONS.checkCircle}
                  iconPos={"left"}
                  size={"extraSmall"}
                  type={showNewTopicInput ? "disabled" : "success"}
                  text="Done"
                  onClick={_submitTopicSelection}
                />
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </>
    );
  }
};

export default ResourceTopicSelector;
