import React, { useState, useEffect } from "react";
import axios from "axios";
import GeneralHelper from "../../../shared/helper-methods/general-helpers";

const LinkPreviewImage = ({ link, staticImageUrl = "", ...rest }) => {
  // State to hold the image URL
  const [imageUrl, setImageUrl] = useState(
    "https://t4.ftcdn.net/jpg/00/93/85/69/360_F_93856984_YszdhleLIiJzQG9L9pSGDCIvNu5GEWCc.jpg"
  ); // Replace with your default image path

  // Function to get a static link
  const _getStaticLink = (url) => {
    let finalUrl = "";
    if (url?.indexOf("youtube.com/watch?v=") > -1) {
      const parts = url.split("youtube.com/watch?v=");
      if (parts?.length === 2)
        finalUrl = `https://img.youtube.com/vi/${parts[1]}/0.jpg`;
    } else if (url?.indexOf("youtu.be/") > -1) {
      const parts = url.split("youtu.be/");
      if (parts?.length === 2)
        finalUrl = `https://img.youtube.com/vi/${parts[1]}/0.jpg`;
    } else if (GeneralHelper.isTwinklSite(url)) {
      finalUrl = "https://www.twinkl.co.in/images/twinkl_logo_twitter_card.jpg";
    } else {
      finalUrl = "";
    }
    return finalUrl;
  };

  useEffect(() => {
    // Function to fetch the image URL
    const fetchImageUrl = async () => {
      if (staticImageUrl?.length) {
        setImageUrl(staticImageUrl);
        return;
      }
      // First try to get a static link
      let finalUrl = _getStaticLink(link);
      if (!finalUrl?.length) {
        try {
          const response = await axios.get(
            `${window.REACT_APP_IFRAME_PROXY}/image-parse?path=${link}`
          ); // Replace with your GET endpoint
          if (response.data?.length) {
            finalUrl = response.data;
          }
        } catch (error) {
          console.error("Error fetching image URL:", error);
          // Optionally handle error, e.g., setting a fallback image or a flag
        }
      }
      // At end if no image found, set default image
      if (!finalUrl?.length) {
        finalUrl = "https://quester.io/pwa-icons/icon-512x512.png";
      }
      setImageUrl(finalUrl); // Assuming the response contains the image URL
    };

    fetchImageUrl();
  }, [link, staticImageUrl]); // Dependency array to re-run the effect if the link changes

  return <img src={imageUrl} alt="Dynamic content" {...rest} />;
};

export default LinkPreviewImage;
