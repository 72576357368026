import CommentApi from "./comment-api";
import NotificationsApi from "./notification-api";
import OrgApi from "./org-api";
import QuestApi from "./quest-api";
import SubscriptionApi from "./subscription-api";
import TeamResourcesApi from "./team-resource-api";
import UserApi from "./user-api";
import VoteApi from "./vote-api";
import ResourceAPI from "./resource-api";

const ApiCalls = {
  org: OrgApi,
  quest: QuestApi,
  teamResource: TeamResourcesApi,
  user: UserApi,
  vote: VoteApi,
  subscripion: SubscriptionApi,
  notificationsApi: NotificationsApi,
  comment: CommentApi,
  resource: ResourceAPI,
};

export default ApiCalls;
