import React from 'react';
import './buttons.scss';

{/*
    Size
        extraSmall
        small
        medium
        large

    Icon - SVG

    IconPos
        left
        right
        null = no icon

    Type
        default = white/black
        brand = yellow
        contrast = black/white
        success
        danger

    Text = "Untitled"

    Disabled = true/false


    extraClasses = "className1 className2"
*/}

const Button = (props) => {
    var Size = props.size ? props.size : "medium";
    var Icon = props.icon ? props.icon : "";
    var IconPos = props.iconPos ? props.iconPos : "";
    var Type = props.type ? props.type : "default";
    var Text = props.text ? props.text : "";
    var Disabled = props.disabled ? "disabled" : "";
    var extraClasses = props.extraClasses ? props.extraClasses : "";
    const onclick = props.onClick ? props.onClick : () => {};

    return (
        <div className={"buttonWrapper " + Size + " " + Type + " " + Disabled + " " + extraClasses} onClick={onclick} {...props} >
            <div className="buttonInner">
                { (IconPos == "left") ? <div className="btnIcon">{Icon}</div> : "" }
                { Text ? <div className="btnText">{Text}</div> : "" }
                { (IconPos == "right") ? <div className="btnIcon">{Icon}</div> : "" }
            </div>
        </div>
    );
}

export default Button;
