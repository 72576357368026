const CollectionStoreActionTypes = {
  SET_ACTIVE_COLLECTION: "COLLECTION_STORE->SET_ACTIVE_COLLECTION",
  SET_ACTIVE_COLLECTION_ID: "COLLECTION_STORE->SET_ACTIVE_COLLECTION_ID",
  SET_ACTIVE_COLLECTION_ITEMS: "COLLECTION_STORE->SET_ACTIVE_COLLECTION_ITEMS",
  TOGGLE_COLLECTION_LOADER_STATUS:
    "COLLECTION_STORE->TOGGLE_COLLECTION_LOADER_STATUS",
  TOGGLE_COLLECTION_ITEMS_LOADER_STATUS:
    "COLLECTION_STORE->TOGGLE_COLLECTION_ITEMS_LOADER_STATUS",
  CLEAR_COLLECTION_DATA: "COLLECTION_STORE->CLEAR_COLLECTION_DATA",
};

export default CollectionStoreActionTypes;
