import React, { useState } from "react";
import ICONS from "../../../../assets/quester-icons";
import Button from "../../../../components/buttons/buttons";
import { useSelector } from "react-redux";
import CommunityMembersViewer from "../community-members-viewer/community-members-viewer";
import AuthHelper from "../../../../shared/helper-methods/auth-helper";
import CommunityUtils from "../../community-utils";
import useKeyCloakSafe from "../../../../shared/hooks/useSafeKeycloak";
import "./community-info-viewer.scss";
import MobileDrawer from "../../../../components/mobile-drawer/mobile-drawer";
import CommunityIcon from "../community-icon/community-icon";
import SponsorHeader from "../../../../features/sponsorship/components/sponsor-header/sponsor-header";
import TypeFilter from "../../../../components/type-filter/type-filter";
import CommunityLabel from "../community-label/community-label";
import AddItemFlow from "../../../../components/add-item-flow/add-item-flow";
import OutsideClickHandler from "react-outside-click-handler";
import useDeepCompareEffect from "use-deep-compare-effect";
const CommunityInfoViewer = () => {
  const { activeCommunity } = useSelector((state) => state.communityStore);
  const { keycloak } = useKeyCloakSafe();
  const [joining, setJoining] = useState(false);
  const [curationAccess, setCurationAccess] = useState(false);

  const [showDrawer, setShowDrawer] = useState(false);

  const [showAddContentButtons, setShowAddContentButtons] = useState(false);

  useDeepCompareEffect(() => {
    const hasCurationAccess = CommunityUtils.checkIfHasCurationAccess();
    setCurationAccess(hasCurationAccess);
  }, [activeCommunity || {}]);

  return (
    <>
      <div className="communityHeader">
        <div className="left">
          <div className="imageWrapper">
            {/* <img src={activeCommunity.orgIconImageURL} alt="" /> */}
            <CommunityIcon orgIconImageURL={activeCommunity.orgIconImageURL} />
          </div>
          <div className="titleTextWrapper">
            <div className="titleText">
              {" "}
              {activeCommunity.organisationDisplayName}{" "}
            </div>
            <SponsorHeader />
          </div>
        </div>

        <div className="buttons">
          <div className="aboutWrapper" onClick={(e) => setShowDrawer(true)}>
            <Button
              size={"small"}
              icon={ICONS.infoCircle}
              iconPos={"left"}
              type={"default"}
              text="About"
            />

            <CommunityMembersViewer />
          </div>
          {joining ? (
            <></>
          ) : (
            <>
              {
                // Hide if community id is "xYyMG49YiBE"
                activeCommunity?.uuid === "xYyMG49YiBE" ? (
                  <></>
                ) : (
                  <>
                    <div className="joinWrapper">
                      {activeCommunity?.roles?.length &&
                      keycloak?.authenticated ? (
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            setJoining(true);
                            CommunityUtils.leaveActiveOrg();
                            setJoining(false);
                          }}
                          size={"small"}
                          icon={ICONS.crossIcon}
                          iconPos={"left"}
                          type={"default"}
                          text="Leave"
                        />
                      ) : (
                        <Button
                          onClick={async (e) => {
                            e.stopPropagation();
                            if (AuthHelper.isLoggedIn()) {
                              setJoining(true);
                              await CommunityUtils.joinActiveOrg();
                              setJoining(false);
                            } else {
                              AuthHelper.login();
                            }
                          }}
                          size={"small"}
                          icon={ICONS.plus}
                          iconPos={"left"}
                          type={"brand"}
                          text="Join"
                        />
                      )}
                    </div>
                  </>
                )
              }
            </>
          )}
        </div>
        {curationAccess ? (
          <>
            <div
              className={
                "addContentButtonsWrapper " +
                (showAddContentButtons ? "show" : "")
              }
            >
              <div className="addContentButtonsInner">
                <div className="addItemButtonWrapper">
                  <Button
                    size={"small"}
                    icon={ICONS.addFile}
                    iconPos={"left"}
                    type={"default"}
                    text="Add content"
                    extraClasses="addItemButton"
                    onClick={(e) => {
                      setShowAddContentButtons(!showAddContentButtons);
                    }}
                  />
                  <div className="newtag">New</div>
                </div>

                <OutsideClickHandler
                  onOutsideClick={() => setShowAddContentButtons(false)}
                >
                  <div className="addContentDropdown">
                    <div
                      className="addContentDropdownItem"
                      onClick={(e) => {
                        e.stopPropagation();
                        CommunityUtils.showCommunityAddResourceWizard();
                        setShowAddContentButtons(false);
                      }}
                    >
                      {" "}
                      {ICONS.plus} <span>Recommendation</span>{" "}
                    </div>
                    {/* <div className="addContentDropdownItem">
                      {" "}
                      {ICONS.list2} <span>Collection</span>{" "}
                    </div> */}
                  </div>
                </OutsideClickHandler>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      <MobileDrawer
        isActive={showDrawer}
        onClose={(e) => setShowDrawer(false)}
        headerTitle="About this community"
        headerIcon={ICONS.infoCircle}
        extraClass="communityInfoDrawer"
      >
        <CommunityMembersViewer />
      </MobileDrawer>
    </>
  );
};

export default CommunityInfoViewer;
