import React, { Component, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SponsorUtils from "../../sponsor-utils";
import ICONS from "../../../../assets/quester-icons";

const SponsorComment = () => {
  const location = useLocation();
  const [isSponosored, setIsSponsored] = useState(false);

  useEffect(() => {
    const isSponsored = SponsorUtils.checkIfSponsorCommunity();
    setIsSponsored(isSponsored);
  }, [location]);

  if (!isSponosored) {
    return <></>;
  } else {
    return (
      <div
        className="sponsoredComment"
        onClick={(e) => {
          e.preventDefault();
          SponsorUtils.onSponsorLinkClick("comment");
        }}
      >
        <div class="commentThread">
          <div class="comment topLevel">
            <div class="userDetails ">
              <div class="userImage" title="Sponsor">
                <img
                  src={require("../../../../assets/images/sponsor-white.png")}
                  alt=""
                />
              </div>

              <div class="userRight">
                <div className="top">
                <div class="left">
                  <div class="username" title="Spotify">
                    @shepherd
                  </div>
                  <span>·</span>
                  <div class="sponsorStamp">Sponsor</div>
                </div>
                </div>
              </div>
            </div>

            <div class="commentInner">
              <div class="text">
                <span class="d-inline">
                  <span>
                  Dive deeper into these notes with Docchat
                  </span>
                </span>

                <div class="buttons">
                  <span className="button">
                    {ICONS.sparkleFilled} Try for free
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default SponsorComment;
