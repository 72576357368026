import React, { useEffect } from "react";
import CollectionView from "../../../collection/components/collection-view/collection-view";
import { useSelector } from "react-redux";
import UserUtils from "../../user-utils";

const FollowingCollectionsViewer = () => {
  const { followingQuests } = useSelector((state) => state.userStore);

  const _initiateLoad = () => {
    UserUtils.loadFollowingQuests();
  };

  const _unfollow = (e, collection) => {
    e.stopPropagation();
    UserUtils.unfollowCollection(collection);
  }

  useEffect(() => {
    _initiateLoad();
  }, []);
  return (
    <div
      class="tab-pane fade show active"
      id="savedCollection"
      role="tabpanel"
      aria-labelledby="saved-collections-tab"
    >
      {followingQuests?.map((quest) => (
        <CollectionView
          key={quest.id}
          collection={quest}
          // hideSaveButton={true}
          isSaved={true}
          hideShareButton={true}
          onUnfollow={e => _unfollow(e, quest)}
        />
      ))}
    </div>
  );
};

export default FollowingCollectionsViewer;
