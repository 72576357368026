import cloneDeep from "clone-deep";
import moment from "moment";
import SiteWiseEvents from "../../declarations/site-wise-events";
import CollectionUtils from "../../entities/collection/collection-utils";
import ApiCalls from "../../shared/api";
import AuthHelper from "../../shared/helper-methods/auth-helper";
import CommentActivities from "../../shared/utils/activity-recorder/activity-helpers/comment-activities";
import { EventEmitter } from "../../shared/utils/event-emitter";
import { commentStoreActions } from "../../stores/active-quest-comment-store/actions";
import { store } from "../../stores/store";

const QuestCommentsHelper = {
  // All public methods
  initiateCommentLoad: (questId) => {
    // Reset everything
    QuestCommentsHelper._resetStore();
    // Load top level comments
    QuestCommentsHelper.loadMoreTopLevelComments(questId);
  },
  loadMoreTopLevelComments: async (paramQuestId = null) => {
    // Check if can load more
    let questId = paramQuestId;
    const { pagination } = QuestCommentsHelper._getCommentStore();
    // If can load update pagination
    if (pagination.canLoadMore) {
      // Update pagination
      QuestCommentsHelper._increasePagination();
      // Show loader
      QuestCommentsHelper._showLoader();
      if (!questId) {
        const collectionStore = QuestCommentsHelper._getQuestStore();
        if (collectionStore.activeCollectionId) {
          questId = collectionStore.activeCollectionId;
        }
      }
      if (questId) {
        // Call the API to get the comments
        let comments = [];
        if (AuthHelper.isLoggedIn()) {
          const { data } = await ApiCalls.quest.private.fetchQuestComments(
            questId
          );
          comments = data;
        } else {
          const { data } = await ApiCalls.quest.public.fetchQuestComments(
            questId
          );
          comments = data;
        }
        if (comments?.length) {
          // Merge with existing comments
          const { allComments } = QuestCommentsHelper._getCommentStore();
          const formattedComments =
            QuestCommentsHelper._formatComments(comments);
          const newComments = [...allComments, ...formattedComments];
          QuestCommentsHelper._dispatchAction(
            commentStoreActions.staticActions.setAllComments(newComments)
          );
        } else {
          // Update pagination
          QuestCommentsHelper._markAsPageEnd();
        }
        // Hide loader
        QuestCommentsHelper._hideLoader();
        // Update the comments in redux
      }
    }
  },
  saveEditedComment: async ({ address, value }) => {
    // Find the comment by address
    const { allComments } = QuestCommentsHelper._getCommentStore();
    let comment = QuestCommentsHelper._findCommentByAddress(
      allComments,
      address
    );
    if (!comment) {
      return;
    }
    // Call the API to save the comment
    const jsonValue = QuestCommentsHelper.formatMarkdownToJSON(value);
    // Update value in the comment (redux)
    comment.commentData = jsonValue;
    comment._markDownValue = value;
    // Increase version number
    const payloadComment = cloneDeep(comment);
    comment.versionNumber++;
    comment.isEdited = true;
    QuestCommentsHelper._dispatchAction(
      commentStoreActions.staticActions.setAllComments(allComments)
    );
    // Update nested comments
    QuestCommentsHelper._updateCurrentNestedViewFromAllComments();
    // Call the API to save the comment
    try {
      const { data } = await ApiCalls.quest.private.updateQuestComment(
        comment.id,
        payloadComment
      );
      if (data?.length) {
        comment = {
          ...comment,
          ...data[0],
        };
        // Format the comment
        comment = QuestCommentsHelper._formatComment(comment);
        // Update value in the comment (redux)
        QuestCommentsHelper._dispatchAction(
          commentStoreActions.staticActions.setAllComments(allComments)
        );
      }
    } catch (error) {
      throw error;
    }
  },

  saveNewComment: async ({ parentAddress = null, value }) => {
    // Call the API to save the comment
    try {
      // Get the parent comment
      const collectionStore = QuestCommentsHelper._getQuestStore();
      let { allComments } = QuestCommentsHelper._getCommentStore();
      let parentComment = null;
      if (parentAddress) {
        parentComment = QuestCommentsHelper._findCommentByAddress(
          allComments,
          parentAddress
        );
      }
      const payload = {
        commentData: QuestCommentsHelper.formatMarkdownToJSON(value),
      };
      if (!parentComment) {
        // That means it is a top level comment so send with questId
        let questId = "";
        if (collectionStore.activeCollectionId) {
          questId = collectionStore.activeCollectionId;
        }
        if (!questId?.length) {
          return;
        }
        payload.questId = questId;
      } else {
        payload.parentId = parentComment.id;
      }
      const { data: newComment } = await ApiCalls.quest.private.addQuestComment(
        payload
      );
      const newFormattedComment = QuestCommentsHelper._formatComment(
        newComment,
        parentComment
      );
      const quest = QuestCommentsHelper._getActiveQuest();
      CommentActivities.addNewCommentActivity({
        quest: collectionStore.activeCollection,
        comment: newFormattedComment,
      });
      if (!parentComment) {
        // That means it is a top level comment
        // Push to all comments at first position
        allComments = [newFormattedComment, ...allComments];
      } else {
        // Update value in the comment (redux)
        if (!parentComment.replies?.length) {
          parentComment.replies = [];
        }
        parentComment.replies = [newFormattedComment, ...parentComment.replies];
      }
      QuestCommentsHelper._dispatchAction(
        commentStoreActions.staticActions.setAllComments(allComments)
      );
      QuestCommentsHelper._updateCurrentNestedViewFromAllComments();
      // Also update quest items with comment count if has any reference
      const referencedQuestItems = newFormattedComment.commentData
        .filter((json) => json.e === "questItem")
        .map((json) => json.v);
      if (referencedQuestItems?.length) {
        QuestCommentsHelper._updateQuestItemsWithCommentCount({
          addedItems: referencedQuestItems,
        });
      }
      // Also increase comment count in the collection item if a questitem is referenced
      const questItemUuids = newFormattedComment.commentData
        .filter((json) => json.e === "questItem")
        .map((json) => json.v);
      CollectionUtils.increaseCommentCountInCollectionItems(questItemUuids);
    } catch (error) {
      throw error;
    }

    // Update value in the comment (redux)
  },
  countCommentsAndAnyLevelReplies: (specificComment = null) => {
    const { allComments } = QuestCommentsHelper._getCommentStore();
    let count = 0;

    const countReplies = (comment) => {
      count++;
      if (comment.replies?.length) {
        comment.replies.forEach((reply) => {
          countReplies(reply);
        });
      }
    };

    let commentToCountFrom = allComments;
    if (specificComment) {
      commentToCountFrom = specificComment.replies;
    }
    commentToCountFrom.forEach((comment) => {
      countReplies(comment);
    });

    return count;
  },
  toggleCommentLike: async ({ address }) => {
    // First find the comment by address
    // Update the like value
    const collectionStore = QuestCommentsHelper._getQuestStore();
    const { allComments } = QuestCommentsHelper._getCommentStore();
    const comment = QuestCommentsHelper._findCommentByAddress(
      allComments,
      address
    );
    if (!comment) {
      return;
    }
    if (Object.hasOwnProperty.call(comment, "like")) {
      comment.like = !comment.like;
    } else {
      comment.like = true;
    }
    if (comment.like) {
      comment.likeCount++;
      const quest = QuestCommentsHelper._getActiveQuest();
      CommentActivities.addLikeCommentActivity({
        quest: collectionStore.activeCollection,
        comment,
      });
    } else {
      comment.likeCount--;
    }
    // Update value in the comment (redux)
    QuestCommentsHelper._dispatchAction(
      commentStoreActions.staticActions.setAllComments(allComments)
    );
    QuestCommentsHelper._updateCurrentNestedViewFromAllComments();
    // Call the API to save the like
    try {
      await ApiCalls.quest.private.toggleQuestCommentLike(comment.id);
    } catch (error) {
      throw error;
    }
  },
  toggleCommentAccordian: ({ address, status }) => {
    // Update value in the comment (redux)
    const { allComments } = QuestCommentsHelper._getCommentStore();
    const comment = QuestCommentsHelper._findCommentByAddress(
      allComments,
      address
    );
    if (!comment) {
      return;
    }
    comment._isExpanded = status;
    QuestCommentsHelper._dispatchAction(
      commentStoreActions.staticActions.setAllComments(allComments)
    );
  },
  formatJSONToMarkdown: (jsonArray) => {
    // Convert JSON to markdown using private method
    let markdown = "";
    if (jsonArray?.length) {
      jsonArray.forEach((json) => {
        if (json.e === "text") {
          markdown += json.v;
        } else if (json.e === "questItem") {
          markdown += `@[${json.l}](${json.v})`;
        }
      });
    }
    return markdown;
  },
  formatMarkdownToJSON: (markdown) => {
    let jsonArray = [];
    let regex = /(@\[([^\]]*)\]\(([^\)]*)\))/g;
    let lastIndex = 0;
    let match;
    while ((match = regex.exec(markdown))) {
      const textBeforeMatch = markdown.substring(lastIndex, match.index);
      if (textBeforeMatch) {
        jsonArray.push({
          e: "text",
          v: textBeforeMatch,
          l: textBeforeMatch,
        });
      }
      jsonArray.push({
        e: "questItem",
        l: match[2],
        v: match[3],
      });
      lastIndex = match.index + match[0].length;
    }
    const textAfterLastMatch = markdown.substring(lastIndex);
    if (textAfterLastMatch) {
      jsonArray.push({
        e: "text",
        v: textAfterLastMatch,
        l: textAfterLastMatch,
      });
    }
    return jsonArray;
  },
  deleteComment: async (commentAddress) => {
    // Update value in the comment (redux)
    let { allComments } = QuestCommentsHelper._getCommentStore();
    const comment = QuestCommentsHelper._findCommentByAddress(
      allComments,
      commentAddress
    );
    if (!comment) {
      return;
    }
    // If there are nested comments, don't delete, just update property called isDeleted
    if (comment.replies?.length) {
      comment.isDeleted = true;
    } else {
      // Delete the comment
      // Update value in the comment (redux)
      const parentAddress = comment._address
        .split("-->>")
        .slice(0, -1)
        .join("-->>");
      const parentComment = QuestCommentsHelper._findCommentByAddress(
        allComments,
        parentAddress
      );
      if (!parentComment) {
        // TOp level comment
        allComments = allComments.filter((c) => c.id !== comment.id);
      } else {
        parentComment.replies = parentComment.replies.filter(
          (reply) => reply.id !== comment.id
        );
      }
    }
    QuestCommentsHelper._dispatchAction(
      commentStoreActions.staticActions.setAllComments(allComments)
    );
    QuestCommentsHelper._updateCurrentNestedViewFromAllComments();
    // Update quest items with comment count if has any reference
    const referencedQuestItems = comment.commentData
      .filter((json) => json.e === "questItem")
      .map((json) => json.v);
    if (referencedQuestItems?.length) {
      QuestCommentsHelper._updateQuestItemsWithCommentCount({
        removedItems: referencedQuestItems,
      });
    }
    // Call the API to delete the comment
    try {
      await ApiCalls.quest.private.deleteQuestComment(comment.id);
    } catch (error) {
      throw error;
    }
  },
  showCommentViewer: () => {
    // Show the comment viewer (just redux update)\
    const collectionStore = QuestCommentsHelper._getQuestStore();
    QuestCommentsHelper._dispatchAction(
      commentStoreActions.staticActions.setExpanded(true)
    );
    CommentActivities.recordCommentViewActivity({
      quest: collectionStore.activeCollection,
    });
    EventEmitter.dispatch(SiteWiseEvents.openCommentBar);
  },
  hideCommentViewer: () => {
    QuestCommentsHelper.deactivateReferenceMode();
    // Reset to top level
    QuestCommentsHelper.openTopLevel();
    // Hide the comment viewer (just redux update)
    QuestCommentsHelper._dispatchAction(
      commentStoreActions.staticActions.setExpanded(false)
    );
  },
  getFormattedCreatorDetails: (comment) => {
    // Format the creator details
    const name = comment.userDisplayName?.length
      ? comment.userDisplayName
      : comment.ownerId;
    return {
      name,
      nameInitials: name[0],
    };
  },
  activateReferenceMode: async (questItemUuid) => {
    // Showloader
    QuestCommentsHelper._showLoader();
    // Activate reference mode (redux update)
    QuestCommentsHelper._dispatchAction(
      commentStoreActions.staticActions.setReferenceView(true)
    );
    QuestCommentsHelper._dispatchAction(
      commentStoreActions.staticActions.setReferenceQuestItemId(questItemUuid)
    );
    // Call quest store to highlight the quest item
    // Call the API to get the comments
    const collectionStore = QuestCommentsHelper._getQuestStore();
    let questId = "";
    if (collectionStore.activeCollectionId) {
      questId = collectionStore.activeCollectionId;
    }
    if (!questId?.length) {
      return;
    }
    let comments = [];
    if (AuthHelper.isLoggedIn()) {
      const { data } =
        await ApiCalls.quest.private.fetchQuestCommentsWithReference(
          questId,
          questItemUuid
        );
      comments = data;
    } else {
      const { data } =
        await ApiCalls.quest.public.fetchQuestCommentsWithReference(
          questId,
          questItemUuid
        );
      comments = data;
    }
    // Hide loader
    QuestCommentsHelper._hideLoader();
    // Format the comments
    comments = QuestCommentsHelper._formatComments(comments);
    // Update the comments in redux
    QuestCommentsHelper._dispatchAction(
      commentStoreActions.staticActions.setReferenceViewComments(comments)
    );
  },
  deactivateReferenceMode: () => {
    // Deactivate reference mode (redux update)
    QuestCommentsHelper._dispatchAction(
      commentStoreActions.staticActions.setReferenceView(false)
    );
    // Call quest store to unhighlight the quest item
  },
  openNestedLevel: (commentAddress) => {
    // Open the nested level (just redux update)
    QuestCommentsHelper._dispatchAction(
      commentStoreActions.staticActions.setRootView(false)
    );
    QuestCommentsHelper._dispatchAction(
      commentStoreActions.staticActions.setNestedLevelRootAddress(
        commentAddress
      )
    );
    // Find the comment by address
    const { allComments } = QuestCommentsHelper._getCommentStore();
    const comment = QuestCommentsHelper._findCommentByAddress(
      allComments,
      commentAddress
    );
    if (!comment) {
      return;
    }
    // Update the nested comments (redux update)
    QuestCommentsHelper._dispatchAction(
      commentStoreActions.staticActions.setActiveNestedComments(comment.replies)
    );
  },
  loadPreviousLevels: () => {
    // Load the previous levels (just redux update)
    // Find the comment by address
    const { allComments } = QuestCommentsHelper._getCommentStore();
    const { nestedLevelRootAddress } = QuestCommentsHelper._getCommentStore();
    const addressParts = nestedLevelRootAddress.split("-->>");
    const parentAddress = addressParts.slice(0, -3).join("-->>");
    const parentComment = QuestCommentsHelper._findCommentByAddress(
      allComments,
      parentAddress
    );
    if (!parentComment) {
      // Open top level
      QuestCommentsHelper.openTopLevel();
      return;
    }
    // Update the nested comments (redux update)
    QuestCommentsHelper._dispatchAction(
      commentStoreActions.staticActions.setActiveNestedComments(
        parentComment.replies
      )
    );
    // Update the nested level (just redux update)
    QuestCommentsHelper._dispatchAction(
      commentStoreActions.staticActions.setNestedLevelRootAddress(parentAddress)
    );
  },
  openTopLevel: () => {
    // Open the top level (just redux update)
    QuestCommentsHelper._dispatchAction(
      commentStoreActions.staticActions.setRootView(true)
    );
    QuestCommentsHelper._dispatchAction(
      commentStoreActions.staticActions.setNestedLevelRootAddress("")
    );
    // Update the nested comments (redux update)
    QuestCommentsHelper._dispatchAction(
      commentStoreActions.staticActions.setActiveNestedComments([])
    );
  },
  // SHow like min ago, 1 hour ago and if not same day, show date
  formatDateTime: (dateTime) => {
    const date = new Date(dateTime);
    const now = new Date();
    const diff = now - date;
    const diffInMinutes = diff / 1000 / 60;
    let isFormated = false;
    if (diffInMinutes < 1) {
      isFormated = true;
      return "just now";
    } else if (diffInMinutes < 60) {
      const minutes = Math.floor(diffInMinutes);
      isFormated = true;
      return `${minutes} ${minutes === 1 ? "min" : "mins"} ago`;
    }
    const diffInHours = diffInMinutes / 60;
    if (diffInHours < 24) {
      isFormated = true;
      const hours = Math.floor(diffInHours);
      return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
    }
    if (!isFormated) {
      return moment(date).format("DD/MM/YY");
    }
    return date.toLocaleDateString();
  },
  // Activate the comment viewer if not already activated
  // Start composing a new root level comment and add a reference to the quest item
  composeWithQuestItem: (questItem) => {
    // Activate the comment viewer if not already activated
    const { expanded } = QuestCommentsHelper._getCommentStore();
    if (!expanded) {
      QuestCommentsHelper.showCommentViewer();
    }
    // Prepare a new comment with the quest item reference in the markdown
    const markdown = `@[${questItem.title}](${questItem.uuid})`;
    // console.log('markdown 2222222222221', markdown);
    setTimeout(() => {
      EventEmitter.dispatch("ADD_NEW_COMMENT", markdown);
    }, 100);
  },
  clearCommentsAndPagination: () => {
    QuestCommentsHelper._dispatchAction(
      commentStoreActions.staticActions.setAllComments([])
    );
    QuestCommentsHelper._dispatchAction(
      commentStoreActions.staticActions.clearPagination()
    );
  },
  // All private methods (starting with underscore)
  _updateQuestItemsWithCommentCount: async ({
    addedItems = [],
    removedItems = [],
  }) => {
    if (addedItems?.length) {
      for (let addedItem of addedItems) {
        // QuestItemHelperMethods.increaseQuestItemCommentCount(addedItem);
      }
    }
    if (removedItems?.length) {
      for (let removedItem of removedItems) {
        // QuestItemHelperMethods.decreaseQuestItemCommentCount(removedItem);
      }
    }
  },
  _findCommentByAddress: (comments, address) => {
    const addressParts = address.split("-->>");
    let currentComment = null;
    for (let i = 0; i < addressParts.length; i++) {
      const addressPart = addressParts[i];
      if (currentComment) {
        currentComment = currentComment.replies.find(
          (comment) => comment.id === addressPart
        );
      } else {
        currentComment = comments.find((comment) => comment.id === addressPart);
      }
      if (!currentComment) {
        return null;
      }
    }
    return currentComment;
  },
  _resetPagination: () => {
    QuestCommentsHelper._dispatchAction(
      commentStoreActions.staticActions.clearPagination()
    );
  },
  _increasePagination: () => {
    const { pagination } = QuestCommentsHelper._getCommentStore();
    const newPagination = {
      ...pagination,
      currentPage: pagination.page + 1,
    };
    console.log("pagination 00009 quest:>> ", pagination);
    QuestCommentsHelper._dispatchAction(
      commentStoreActions.staticActions.setPagination(newPagination)
    );
  },
  _showLoader: () => {
    QuestCommentsHelper._dispatchAction(
      commentStoreActions.staticActions.toggleLoader(true)
    );
  },
  _hideLoader: () => {
    QuestCommentsHelper._dispatchAction(
      commentStoreActions.staticActions.toggleLoader(false)
    );
  },
  _formatComments: (comments, parentComment = null) => {
    const formattedComments = comments.map((comment) => {
      const formattedComment = QuestCommentsHelper._formatComment(
        comment,
        parentComment
      );
      if (comment.replies?.length) {
        formattedComment.replies = QuestCommentsHelper._formatComments(
          comment.replies,
          formattedComment
        );
      }
      return formattedComment;
    });
    return formattedComments;
  },
  _formatComment: (comment, parentComment) => {
    // Format the comments
    // Get user info from user store
    const { userStore } = QuestCommentsHelper._getAllStores();
    let commentAddress = "";
    if (parentComment) {
      comment.parentCommentId = parentComment.id;
      commentAddress = `${parentComment._address}-->>`;
    }
    commentAddress += comment.id;
    let isOwnComment = false;
    if (userStore.profileData.ownerId === comment.ownerId) {
      isOwnComment = true;
    }
    return {
      ...comment,
      _markDownValue: QuestCommentsHelper.formatJSONToMarkdown(
        comment.commentData
      ),
      _address: commentAddress,
      _isExpanded: true,
      _creatorDetails: QuestCommentsHelper.getFormattedCreatorDetails(comment),
      _isOwnComment: isOwnComment,
    };
  },
  _markAsPageEnd: () => {
    const { pagination } = QuestCommentsHelper._getCommentStore();
    const newPagination = {
      ...pagination,
      canLoadMore: false,
    };
    QuestCommentsHelper._dispatchAction(
      commentStoreActions.staticActions.setPagination(newPagination)
    );
  },
  _updateCurrentNestedViewFromAllComments: () => {
    const { allComments, nestedLevelRootAddress, isRootView } =
      QuestCommentsHelper._getCommentStore();
    if (!isRootView) {
      const addressParts = nestedLevelRootAddress.split("-->>");
      let currentComment = null;
      for (let i = 0; i < addressParts.length; i++) {
        const addressPart = addressParts[i];
        if (currentComment) {
          currentComment = currentComment.replies.find(
            (comment) => comment.id === addressPart
          );
        } else {
          currentComment = allComments.find(
            (comment) => comment.id === addressPart
          );
        }
        if (!currentComment) {
          return null;
        }
      }
      QuestCommentsHelper._dispatchAction(
        commentStoreActions.staticActions.setActiveNestedComments(
          currentComment.replies
        )
      );
    }
  },
  _getActiveQuest: () => {
    const { activeCollection } = QuestCommentsHelper._getQuestStore();
    return activeCollection;
  },
  _resetStore: () => {
    QuestCommentsHelper._dispatchAction(
      commentStoreActions.staticActions.clearStore()
    );
  },
  _getQuestStore: () => {
    const { collectionStore } = store.getState();
    return cloneDeep(collectionStore);
  },
  _getCommentStore: () => {
    const { commentStore } = store.getState();
    return cloneDeep(commentStore);
  },
  _getAllStores: () => {
    const allStores = store.getState();
    return cloneDeep(allStores);
  },
  _dispatchAction: (action) => {
    store.dispatch(action);
  },
};

export default QuestCommentsHelper;
