/* eslint-disable no-unreachable */
import React, { Component, useEffect, useState } from "react";
import ICONS from "../../../../assets/quester-icons";
import { useSelector } from "react-redux";
import QuestCommentsHelper from "../../quest-comments-helper";
import QuestCommentTextViewer from "../quest-comment-text-viewer/quest-comment-text-viewer";
import QuestCommentEditor from "../quest-comment-editor/quest-comment-editor";
import QuestCommentUserImage from "../quest-comment-user-image/quest-comment-user-image";
import CommentComposer from "../comment-composer/comment-composer";

// import FloatingToolTipUtils from "../../../floating-tooltip-action-prompts/floating-tooltip-utils";
import moment from "moment";

import Button from "../../../../components/buttons/buttons";
import AuthHelper from "../../../../shared/helper-methods/auth-helper";
import useKeyCloakSafe from "../../../../shared/hooks/useSafeKeycloak";
import useDeepCompareEffect from "use-deep-compare-effect";
import CollectionUtils from "../../../../entities/collection/collection-utils";
import CommunityUtils from "../../../../entities/community/community-utils";
import "./quest-comment.scss";
import router from "../../../../shared/top-level-declarations/history";
import CommunityCommentsHelper from "../../community-comments-helper";
import CollectionIcon from "../../../../entities/collection/components/collection-icon/collection-icon";

const QuestCommentLove = ({ comment }) => {
  const [loved, setLoved] = useState(comment.like);

  const _toggleLove = (e) => {
    if (!AuthHelper.isLoggedIn()) {
      const { clientX, clientY } = e;
      // FloatingToolTipUtils.show({
      //   toolTipText: "Please log in to like this comment",
      //   actionSupportText: "",
      //   x: clientX,
      //   y: clientY,
      //   marginX: 0,
      //   marginY: "1rem",
      // });
      AuthHelper.login();
      return;
    }
    QuestCommentsHelper.toggleCommentLike({
      address: comment._address,
    });
  };

  return (
    <div
      className={"like " + (comment.like ? "liked" : "")}
      onClick={_toggleLove}
      title={comment.like ? "Unlike this comment" : "Like this comment"}
      style={
        comment?.isDeleted
          ? {
              cursor: "not-allowed",
              pointerEvents: "none",
            }
          : {}
      }
    >
      {ICONS.heartOutline}{" "}
      {comment?.likeCount > 0 ? (
        <span className="likeCount">{comment?.likeCount}</span>
      ) : (
        ""
      )}
    </div>
  );

  return (
    <div
      className={"questCommentloveButton " + (comment.like ? "loved" : "")}
      onClick={_toggleLove}
      style={
        comment?.isDeleted
          ? {
              cursor: "not-allowed",
              pointerEvents: "none",
            }
          : {}
      }
    >
      {ICONS.SVGheartOutline}
      <span>{comment?.likeCount > 0 ? comment?.likeCount : ""}</span>
    </div>
  );
};

const QuestComment = ({
  comment,
  level,
  isReferenceView = false,
  showQuestLink = false,
  isTopLevelCommunityComment = false,
  isCommunityComment = false,
}) => {
  const { keycloak, initialized } = useKeyCloakSafe();
  const [showReply, setShowReply] = useState(false);
  const [expandReplies, setExpandReplies] = useState(false);
  const [isPremium, setIsPremium] = useState(false);
  const [hasPurchased, setHasPurchased] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [inputText, setInputText] = useState(comment?._markDownValue);
  const [totalNestedReplyCount, setTotalNestedReplyCount] = useState(0);
  const [showReplyLoader, setShowReplyLoader] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const [isInputExpanded, setIsInputExpanded] = useState(false);
  const { isExpanded } = useSelector((state) => state.commentStore || {});
  const hasChild = comment.replies?.length > 0;
  const hasParent = !!comment.parentId?.length;
  let nestLevel = isReferenceView
    ? 1
    : comment.treeLevel % 3 === 0
    ? 3
    : comment.treeLevel % 3;
  const imageUrl =
    "https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8fDA%3D&w=1000&q=80";
  const canEdit = comment?._isOwnComment;
  const showReplies = nestLevel < 3;

  const _toggleReplies = (e) => {
    setExpandReplies(!expandReplies);
  };

  const _cancelEdit = (e) => {
    setIsEditing(false);
    setInputText(comment?._markDownValue);
  };

  const _confirmEdit = (e) => {
    setIsEditing(false);
    QuestCommentsHelper.saveEditedComment({
      address: comment._address,
      value: inputText,
    });
  };

  const _confirmDeleteComment = (e) => {
    setIsDeleting(false);
    QuestCommentsHelper.deleteComment(comment._address);
  };

  const _toggleExpandComment = (e) => {
    QuestCommentsHelper.toggleCommentAccordian({
      address: comment._address,
      status: !comment._isExpanded,
    });
  };

  const _viewNestedReplies = (e) => {
    QuestCommentsHelper.openNestedLevel(comment._address);
  };

  const formattedDateTime = QuestCommentsHelper.formatDateTime(
    comment?.createdOn
  );

  const _generateTopLevelClassName = () => {
    let topLevelClassName = "";
    if (!hasParent) {
      topLevelClassName += " commentThread ";
    } else if (hasParent && comment?.treeLevel === 2) {
      topLevelClassName += " firstReplyBlock ";
    } else if (hasParent && comment?.treeLevel > 2) {
      topLevelClassName += " nestedReplyBlock ";
    }
    if (isPremium) {
      topLevelClassName += " expert ";
    }
    return topLevelClassName;
  };

  const _checkIfPremiumAndPurchased = () => {
    let isPremium = false;
    if (comment?.isPremium) {
      isPremium = true;
    }
    const hasPurchased = CommunityUtils.checkIfSubscribed();
    setIsPremium(isPremium);
    setHasPurchased(hasPurchased);
  };

  const _loadCollectionPage = () => {
    // Hide 
    // CommunityCommentsHelper.hideCommentViewer();
    router.navigate(
      `/c/${comment?.owningOrganizationId}/q/${comment?.questId}`
    );
  };

  const _loadReplies = async () => {
    setShowReplyLoader(true);
    await CommunityCommentsHelper.loadTree({
      topLevelComment: comment,
    });
    setExpandReplies(true);
    setShowReplyLoader(false);
  };

  const topLevelClassName = _generateTopLevelClassName();

  useDeepCompareEffect(() => {
    _checkIfPremiumAndPurchased();
    if (comment?.replies?.length) {
      const totalNestedReplyCount =
        QuestCommentsHelper.countCommentsAndAnyLevelReplies(comment);
      setTotalNestedReplyCount(totalNestedReplyCount);
    }
  }, [comment]);

  return (
    <div className={topLevelClassName}>
      <div
        className={`comment topLevel ${isPremium ? "premium" : ""} ${
          isDeleting ? "delete" : ""
        } ${isPremium && !hasPurchased ? "locked" : ""} `}
      >
        <div className={"userDetails " + (!hasParent ? "" : "smallImage")}>
          {/* <div className="userImage" title="John Doe on 5th Jan 23, at 5:00 pm">
            <QuestCommentUserImage comment={comment} />
          </div> */}

          {/* dada ekhane */}
          {/* <div className="userImage premiumImage" title="John Doe on 5th Jan 23, at 5:00 pm">
            <QuestCommentUserImage comment={comment} />
            <div className="premiumBadge">{ICONS.diamond}</div>
          </div> */}

          {/* dada ekhane */}
          {/* <div className="userImage expertImage" title="John Doe on 5th Jan 23, at 5:00 pm">
              <svg viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <pattern id="img" patternUnits="userSpaceOnUse" width="100" height="100">
                    <image xlinkHref="https://farm9.staticflickr.com/8461/8048823381_0fbc2d8efb.jpg" x="-25" width="150" height="100" />
                  </pattern>
                </defs>
                <polygon points="50 1 95 25 95 75 50 99 5 75 5 25" fill="url(#img)"/>
              </svg>
          </div> */}
          <QuestCommentUserImage comment={comment} withWrapper={true} />

          <div className="userRight">
            <div className="top">
              <div className="left">
                <div
                  className="username"
                  title={comment?._creatorDetails?.name}
                >
                  @{comment?._creatorDetails?.name}
                </div>
                <span>·</span>
                <div className="timestamp" title={formattedDateTime}>
                  {formattedDateTime}
                </div>
              </div>

              {comment?.isEdited ? (
                <span className="editedLabel">(edited)</span>
              ) : (
                // <span className="editedTag" title={moment(comment.)}>(edited)</span>
                <></>
              )}
            </div>
            <div className="bottom">
              {isPremium ? (
                <span className="userRole">Expert Member</span>
              ) : (
                <></>
              )}
              {/* memeber, Premium member */}
            </div>
          </div>
        </div>

        <div className="commentInner">
          <div className="text">
            {isEditing ? (
              <QuestCommentEditor
                markDown={inputText}
                onUpdate={setInputText}
              />
            ) : (
              <>
                {comment?.isDeleted ? (
                  <div className="deletedComment">
                    <div className="label">[this comment has been deleted]</div>
                  </div>
                ) : (
                  <QuestCommentTextViewer
                    jsonValue={comment?.commentData}
                    isReferenceView={isReferenceView}
                    isLocked={isPremium && !hasPurchased}
                    onReferenceClick={(questItemUuid) => {
                      if (isCommunityComment) {
                        CommunityCommentsHelper.activateReferenceMode(
                          comment,
                          questItemUuid
                        );
                      } else {
                        QuestCommentsHelper.activateReferenceMode(
                          questItemUuid
                        );
                      }
                    }}
                  />
                )}
              </>
            )}
            {isPremium && !hasPurchased ? (
              <div className="premiumButtonWrap">
                <div
                  className="premiumButton"
                  onClick={(e) => CollectionUtils.showSubscriptionPopup()}
                >
                  <div className="inner">
                    {ICONS.diamondGradient}
                    <div className="label">Unlock with Premium</div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className="buttons">
              {isDeleting ? (
                <div className="w-100 d-flex justify-content-end">
                  <div className="save" onClick={(e) => setIsDeleting(false)}>
                    <Button
                      size={"extraSmall"}
                      icon={ICONS.corssCircle}
                      iconPos={"left"}
                      type={"default"}
                      text={"Cancel"}
                    />
                  </div>
                  <div className="cancel" onClick={_confirmDeleteComment}>
                    <Button
                      size={"extraSmall"}
                      icon={ICONS.checkCircle}
                      iconPos={"left"}
                      type={"danger"}
                      text={"Delete"}
                    />
                  </div>
                </div>
              ) : isEditing ? (
                <div className="w-100 d-flex justify-content-end">
                  <div className="save" onClick={_cancelEdit}>
                    <Button
                      size={"extraSmall"}
                      icon={ICONS.corssCircle}
                      iconPos={"left"}
                      type={"danger"}
                      text={"Cancel"}
                    />
                  </div>
                  <div className="cancel" onClick={_confirmEdit}>
                    <Button
                      size={"extraSmall"}
                      icon={ICONS.checkCircle}
                      iconPos={"left"}
                      type={"success"}
                      text={"Confirm changes"}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className={"reply " + (showReply ? "show" : "")}
                    onClick={(e) => setShowReply(!showReply)}
                    title="Reply to this comment"
                  >
                    {showReply ? ICONS.chevronUp : ICONS.reply}
                  </div>
                  <QuestCommentLove comment={comment} />
                  {canEdit && !comment?.isDeleted ? (
                    <>
                      <div
                        className="edit"
                        onClick={(e) => setIsEditing(true)}
                        title="Edit this comment"
                      >
                        {ICONS.edit}
                      </div>
                      <div
                        className="delete"
                        title="Delete this comment"
                        onClick={(e) => setIsDeleting(true)}
                      >
                        {ICONS.crossBigRed}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
              {isPremium && !hasPurchased ? (
                <div className="premiumButtonWrap">
                  <div
                    className="premiumButton"
                    onClick={(e) => CollectionUtils.showSubscriptionPopup()}
                  >
                    <div className="inner">
                      {ICONS.diamondGradient}
                      <div className="label">Unlock with Premium</div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          {showReply && !isEditing ? (
            <CommentComposer
              isTopLevel={false}
              parentCommentId={comment.parentId}
              parentAddress={comment._address}
              autoFocus={isInputExpanded}
              onSubmit={() => {
                // Collapse the reply box
                setShowReply(false);
                setExpandReplies(true);
                // document
                //   .getElementById(`collapseReply${comment.id}`)
                //   .classList.remove("show");
              }}
            />
          ) : (
            ""
          )}

          <div className="replyAndClose">
            <div className="repliesToggle">
              {isTopLevelCommunityComment && !comment?.replies?.length ? (
                <>
                  {comment?.hasChildren ? (
                    <>
                      {showReplyLoader ? (
                        <span className="replyCount">Loading replies...</span>
                      ) : (
                        <span className="replyCount" onClick={_loadReplies}>
                          Load replies
                        </span>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  <div className="replyUserImages">
                    {comment?.replies?.map((reply, index) => (
                      <div className="replyUserImage" key={reply.uuid}>
                        <QuestCommentUserImage comment={reply} />
                      </div>
                    ))}
                  </div>
                  {comment?.replies?.length ? (
                    <span className="replyCount" onClick={_toggleReplies}>
                      {comment?.replies?.length}{" "}
                      {comment?.replies?.length === 1 ? "reply" : "replies"}
                    </span>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
            {!expandReplies ? (
              ""
            ) : (
              <div
                className="closeToggle"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setExpandReplies(false);
                }}
              >
                Close
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Show nested */}
      {comment?.replies?.length && expandReplies ? (
        <>
          {comment?.replies?.map((reply) => (
            <QuestComment
              comment={reply}
              isCommunityComment={isCommunityComment}
            />
          ))}
        </>
      ) : (
        <>
          {isTopLevelCommunityComment &&
          !comment?.replies?.length &&
          comment.lastReply ? (
            <>
              {/* Based on tree level, keep adding nested topLevelClassName */}
              {
                <QuestComment
                  comment={comment.lastReply}
                  isCommunityComment={isCommunityComment}
                />
                // <div className="firstReplyBlock">
                // </div>
              }
            </>
          ) : (
            <></>
          )}
        </>
      )}
      {showQuestLink ? (
        <div className="commentBreadcrumb">
          <div className="staticText">in</div>
          <div className="collection" onClick={_loadCollectionPage}>
            <div className="imageWrapper">
              <CollectionIcon
                collectionImageLink={
                  comment?.listImageUrl?.length ? comment?.listImageUrl : ""
                }
              />
              {/* <img
                src={require("../../../../assets/images/collection-icon.png")}
                alt=""
              /> */}
            </div>
            <div className="collectionTitle">
              {comment?.listName?.length ? comment?.listName : "Collection"}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default QuestComment;
