import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./layout/layout";
import CommunityLayout from "./pages/communities-page";
import HomePage from "./pages/home-page/home-page";
import ProfilePage from "./pages/profile-page/profile-page";
import UserSettingsPage from "./pages/user-settings-page/user-settings-page";
import TopLevelProviders from "./shared/top-level-providers/top-level-providers";
import CommunityCollectionPage from "./pages/community-collection-page";
import PrivacyPolicy from "./pages/static-pages/privacy-policy";
import TermsAndConditions from "./pages/static-pages/terms-and-conditions";
import "./App.scss";
import OldToNewQuest from "./pages/old-to-new-quest/old-to-new-quest";

function App() {
  return (
    <>
      <TopLevelProviders>
        <Layout>
          {/* <div className={showHome ? "" : "d-none"}> */}
          <div>
            {/* Routes */}
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/my-profile" element={<ProfilePage />} />
              <Route path="/my-settings" element={<UserSettingsPage />} />
              <Route path="/c/:communityId/*" element={<CommunityLayout />} />
              <Route path="/q/:questUuid/*" element={<OldToNewQuest />} />
              {/* <Route
                path="/c/:communityId/q/:questId"
                element={<CommunityCollectionPage />}
              /> */}
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<TermsAndConditions />} />
            </Routes>
          </div>
        </Layout>
        {/* <div className={showHome ? "" : "d-none"}>
          <HomePage />
        </div>

        <div className={showCommunity ? "" : "d-none"}>
          <CommunitiesPage />
        </div>

        <div className={showCollections ? "" : "d-none"}>
          <CommunityCollectionPage />
        </div>

        
        <button className="modeSwitcher" onClick={() => setTheme(theme === "light" ? "dark" : "light")}>
          {theme === "light" ? "Dark" : "Light"} Mode
        </button>

        <button className="homeSwitcher" onClick={() => ShowPage("home")}>Home page</button>
        <button className="communitySwitcher" onClick={() => ShowPage("community")}> Community page </button>
        <button className="collectionSwitcher" onClick={() => ShowPage("collections")}> Collections page </button> */}
      </TopLevelProviders>
    </>
  );
}

export default App;
