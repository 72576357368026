import React, { Component, useState } from "react";
import ICONS from "../../../../assets/quester-icons";
import TypeFilter from "../../../../components/type-filter/type-filter";
import Button from "../../../../components/buttons/buttons";
import { useSelector } from "react-redux";
import LinkPreviewImage from "../../../../components/core/link-previewer/link-previewer";
import useDeepCompareEffect from "use-deep-compare-effect";
import ResourceUtils from "../../resource-utils";
import GeneralHelper from "../../../../shared/helper-methods/general-helpers";
import Spinner from "../../../../components/core/spinner/spinner";
import AIAssitanceFeedback from "../ai-assistance-feedback/ai-assistance-feedback";
import ResourceWizardSteps from "../../constants/resource-wizard-steps";
import ResourceTopicSelector from "../resource-topic-selector/resource-topic-selector";
import ResourceTopic from "../resource-topic/resource-topic";
import ResourceWizardMobileButton from "../resource-wizard-mobile-buttons/resource-wizard-mobile-buttons";

const ResourceTopicEditStep = ({ isAi = true, switchStep = () => {} }) => {
  const { localResource, imageFile, selectedType, selectedTopics } =
    useSelector((state) => state.resourceWizardStore);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isTopicSelectorOpen, setIsTopicSelectorOpen] = useState(false);

  const { activeCommunityLabels } = useSelector(
    (state) => state.communityStore
  );

  const _openTopicSelector = () => {
    setIsTopicSelectorOpen(true);
  };

  const _submit = async () => {
    // Submit the resource
    setIsLoading(true);
    const data = await ResourceUtils.submitResource();
    setIsLoading(false);
    if (data) {
      ResourceUtils.resetWizard();
      // Show success message
      setErrorMessage("");
      GeneralHelper.showToast({
        primaryText: "Resource added successfully",
        additionalClass: "green",
        icon: ICONS.questerIcon,
      });
    } else {
      // Show error message
      setErrorMessage("Failed to add the resource");
      GeneralHelper.showToast({
        primaryText: "Failed to add the resource",
        additionalClass: "error",
        icon: ICONS.questerIconSad,
      });
    }
  };

  return (
    <>
      <div className="innerWrapper">
        <div className="section">
          <div
            className={
              "sectionInnerWrapper step4 addTopic d-grid" + (isAi ? " ai " : "")
            }
          >
            <div
              className="closeIcon"
              onClick={(e) => ResourceUtils.resetWizard()}
            >
              {ICONS.crossIcon}
            </div>{" "}
            {/* this div will change only */}
            <div className="info">
              <div className="stepTitle">
                Looking good? Let's publish it! 😍
              </div>
              <ResourceTopicSelector
                isVisible={isTopicSelectorOpen}
                onDismiss={() => setIsTopicSelectorOpen(false)}
                onSave={() => setIsTopicSelectorOpen(false)}
                selectedTopics={selectedTopics}
                isMobile={true}
              />
              <div className="stepDesc">
                <div class="mainText">
                  Feel free to change anything you need by clicking on it
                </div>
                <div className="aiAssistedText">
                  {ICONS.sparkleFilledGradient4} <span>AI assisted</span>
                </div>
              </div>
            </div>
            <div className="editableInputArea">
              <div
                className="imageWrapper"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  ResourceUtils.switchStep(ResourceWizardSteps.META_DATA_EDIT);
                }}
              >
                <LinkPreviewImage
                  link={localResource?.sourceUrl}
                  staticImageUrl={imageFile}
                  style={{ width: "56px", height: "100%", objectFit: "cover" }}
                />
              </div>

              <div className="right">
                <div className="innerWrapper">
                  <div className="itemTitleWrapper">
                    <div
                      className="itemTitle"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        ResourceUtils.switchStep(
                          ResourceWizardSteps.META_DATA_EDIT
                        );
                      }}
                    >
                      {localResource?.title ? (
                        localResource?.title
                      ) : (
                        <i>Untitled (Click to change the title)</i>
                      )}
                    </div>
                  </div>

                  <div className="bottomWrapper">
                    <div className="topics p-0 border-0">
                      {selectedType ? (
                        <TypeFilter
                          title={selectedType?.name}
                          color={selectedType?.color}
                          key={selectedType?.id}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            ResourceUtils.switchStep(
                              ResourceWizardSteps.PREVIEW_WITH_TYPE_EDIT
                            );
                          }}
                        />
                      ) : (
                        <></>
                      )}
                      {selectedTopics?.map((label, index) => (
                        <ResourceTopic
                          topicTitle={label?.displayName}
                          isSelected={false}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            // _updateTopicSelection(label);
                            _openTopicSelector();
                          }}
                        />
                      ))}
                    </div>

                    <div className="buttons d-md-flex">
                      {selectedTopics?.length ? (
                        <></>
                      ) : (
                        <>
                          <Button
                            icon={ICONS.plus}
                            iconPos={"left"}
                            size={"extraSmall"}
                            type={"contrast"}
                            text="Add Topics"
                            onClick={(e) => setIsTopicSelectorOpen(true)}
                          />
                        </>
                      )}

                      <ResourceTopicSelector
                        isVisible={isTopicSelectorOpen}
                        onDismiss={() => setIsTopicSelectorOpen(false)}
                        onSave={() => setIsTopicSelectorOpen(false)}
                        selectedTopics={selectedTopics}
                      />
                    </div>
                  </div>
                </div>
                <div className="buttons">
                  {/* change the Type to "default" to activate the button */}
                  {isLoading ? (
                    <Spinner withContainer={false} loaderSize="25px" />
                  ) : (
                    <Button
                      size={"small"}
                      type={"brand"}
                      text="Publish now!"
                      onClick={_submit}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <AIAssitanceFeedback />
        </div>
      </div>

      <ResourceWizardMobileButton
        stepNumber={2}
        buttonText={isLoading ? "Publishing..." : "Publish now!"}
        buttonType={isLoading ? "disabled" : "contrast"}
        onClick={_submit}
      />
    </>
  );
};

export default ResourceTopicEditStep;
