import { HttpClient } from "../clients/http-client";

const {
  ProtectedHttpClient,
  PublicHttpClient,
  PublicV2Client,
  ProtectedV2Client,
  PremiumV2Client,
} = HttpClient;

const OrgApi = {
  public: {
    fetchOrgs: () =>
      PublicV2Client.get("/pub/organisations?sortBy=-displayOrder"),
    fetchOrgParties: (orgUuid, includeMemberCount = true) =>
      PublicHttpClient.get(
        `/pub/org-teams?owningOrganizationId=${orgUuid}&pageNo=0&pageSize=500&isIncludeMemberCount=${includeMemberCount}`
      ),
    fetchOrgById: (orgUuid) => PublicV2Client.get(`/orgs/${orgUuid}`),
    fetchOrgResources: (
      orgUuid,
      pageSize,
      pageNo,
      labels = "",
      types = "",
      filterBy = "NON_PREMIUM"
    ) =>
      PublicV2Client.get(
        `/community-items?communityId=${orgUuid}&pageSize=${pageSize}&pageNo=${pageNo}&labels=${labels}&typeIds=${types}&sortBy=-stat.saveCount&filterBy=${filterBy}`
      ),
    fetchOrgCollections: (
      orgUuid,
      labels = "",
      pageSize = 1000,
      pageNo = 0,
      types = "",
      sortBy = "-listname"
    ) =>
      PublicV2Client.get(
        `/collections?orgId=${orgUuid}&labels=${labels}&pageSize=${pageSize}&pageNo=${pageNo}&typeIds=${types}&sortBy=${sortBy}`
      ),
    fetchLabelsByOrgId: (orgUuid) =>
      PublicV2Client.get(`/pub/org-labels?orgId=${orgUuid}`),
    fetchTypesByOrgId: (orgUuid) =>
      PublicV2Client.get(`/pub/types?orgId=${orgUuid}`),
  },
  private: {
    // To fetch all orgs of the user (public + private)
    fetchOrgs: () =>
      ProtectedV2Client.get("/organisations?sortBy=-displayOrder"),
    fetchOrgById: (orgUuid) => ProtectedV2Client.get(`/orgs/${orgUuid}`),
    fetchOrgResources: (
      orgUuid,
      pageSize,
      pageNo,
      labels = "",
      types = "",
      filterBy = "NON_PREMIUM"
    ) =>
      ProtectedV2Client.get(
        `/community-items?communityId=${orgUuid}&pageSize=${pageSize}&pageNo=${pageNo}&labels=${labels}&typeIds=${types}&sortBy=-stat.saveCount&filterBy=${filterBy}`
      ),
    fetchOrgCollections: (
      orgUuid,
      labels = "",
      pageSize = 1000,
      pageNo = 0,
      types = "",
      sortBy = "-listname"
    ) =>
      ProtectedV2Client.get(
        `/collections?orgId=${orgUuid}&labels=${labels}&pageSize=${pageSize}&pageNo=${pageNo}&typeIds=${types}&sortBy=${sortBy}`
      ),
    fetchLabelsByOrgId: (orgUuid) =>
      ProtectedV2Client.get(`/org-labels?orgId=${orgUuid}`),
    joinOrg: (orgUuid) => ProtectedV2Client.post(`/orgs/${orgUuid}/join/me`),
    leaveOrg: (orgUuid) =>
      ProtectedV2Client.delete(`/orgs/${orgUuid}/leave/me`),
    fetchUserOrgs: () => ProtectedV2Client.get("/organisations/me"),
    // To fetch a specific org
    fetchOrgWithStats: (orgUuid) =>
      ProtectedHttpClient.get(`/organisations?orgId=${orgUuid}`),
    // To fetch all org memberships of the current user
    fetchOrgMembership: (orgUuid) =>
      ProtectedHttpClient.get(`/org-members/me?orgId=${orgUuid}`),
    // To fetch all parties of a org for ["org_moderator", "org_owner", "org_admin"
    fetchOrgPartiesForOrgMods: (orgId) =>
      ProtectedHttpClient.get(
        `/org-teams?owningOrganizationId=${orgId}&pageNo=0&pageSize=500`
      ),
    // To fetch all parties of a org for the users who don't have any of these roles: ["org_moderator", "org_owner", "org_admin"
    fetchOrgPartiesForUsers: (orgId) =>
      ProtectedHttpClient.get(
        `/org-teams/me?owningOrganizationId=${orgId}&pageNo=0&pageSize=500`
      ),
    // Create new org
    createOrg: (payload) => ProtectedHttpClient.post(`/organisations`, payload),
    deleteOrg: (orgUuid) =>
      ProtectedHttpClient.delete(`/bff/organizations/${orgUuid}`),
    updateOrg: (orgUuid, payload) =>
      ProtectedHttpClient.put(`/organisations/${orgUuid}`, payload),
    // To fetch all org members
    fetchOrgMembers: (orgUuid, params = "") =>
      ProtectedHttpClient.get(`/organisations/${orgUuid}/org-members${params}`),
    // To add a role to an org member
    addRoleToOrgMembership: ({ membershipUuid, role }) =>
      ProtectedHttpClient.post(`/org-members/${membershipUuid}/roles/${role}`),
    // To delete a role from an org member
    deleteRoleFromOrgMembership: ({ membershipUuid, role }) =>
      ProtectedHttpClient.delete(
        `/org-members/${membershipUuid}/roles/${role}`
      ),
    // To fetch a specific org
    fetchOrg: (orgUuid) => ProtectedHttpClient.get(`/organisations/${orgUuid}`),
    // To delete a role from an org member
    deleteOrgMembership: ({ membershipUuid }) =>
      ProtectedHttpClient.delete(`/org-members/${membershipUuid}`),
    fetchOrgTypes: (orgUuid) =>
      ProtectedV2Client.get(`/types?orgId=${orgUuid}`),
    addCommunityResource: (payload) =>
      ProtectedV2Client.post(`/community-items`, payload),
    toggleCommunityResourceSave: (resourceId) =>
      ProtectedV2Client.post(`/community-items/${resourceId}/save-toggle`),
  },
  premium: {
    fetchOrgCollections: (
      orgUuid,
      labels = "",
      pageSize = 1000,
      pageNo = 0,
      types = "",
      sortBy = "-listname"
    ) =>
      ProtectedV2Client.get(
        `/collections?orgId=${orgUuid}&labels=${labels}&pageSize=${pageSize}&pageNo=${pageNo}&typeIds=${types}&sortBy=${sortBy}`
      ),
    fetchOrgResources: (
      orgUuid,
      pageSize,
      pageNo,
      labels = "",
      types = "",
      filterBy = "NON_PREMIUM"
    ) =>
      PremiumV2Client.get(
        `/community-items?communityId=${orgUuid}&pageSize=${pageSize}&pageNo=${pageNo}&labels=${labels}&typeIds=${types}&sortBy=-stat.saveCount&filterBy=${filterBy}`
      ),
  },
};
export default OrgApi;
