import React, { Component, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SponsorUtils from "../../sponsor-utils";
import ICONS from "../../../../assets/quester-icons";

const SponsorFooter = () => {
  const location = useLocation();
  const [isSponosored, setIsSponsored] = useState(false);

  useEffect(() => {
    const isSponsored = SponsorUtils.checkIfSponsorCommunity();
    setIsSponsored(isSponsored);
  }, [location]);

  if (!isSponosored) {
    return <></>;
  } else {
    return (
      <div
        className="sponsorFooter"
        onClick={(e) => {
          e.preventDefault();
          SponsorUtils.onSponsorLinkClick("footer");
        }}
      >
        <span className="imageWrapper">
          <img
            src={require("../../../../assets/images/sponsor_long.png")}
            alt=""
          />
        </span>
        <span className="text">
          Create flashcards + quizzes from these resources instantly with
          Shepherd
        </span>
        <span className="button">{ICONS.sparkleFilled} Try for free!</span>
      </div>
    );
  }
};

export default SponsorFooter;
