import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';

const useCommunityStats = () => {
    const [totalResources, setTotalResources] = useState(0);
    const [totalCollections, setTotalCollections] = useState(0);

    const {
        activeCommunityResourcesPagination,
        activeCommunityCollectionsPagination,
    } = useSelector((state) => state.communityStore);

    useDeepCompareEffect(() => {
        console.log('activeCommunityResourcesPagination 99998', activeCommunityResourcesPagination);
        console.log('activeCommunityCollectionsPagination 99998', activeCommunityCollectionsPagination);
        setTotalResources(activeCommunityResourcesPagination.totalElements);
        setTotalCollections(activeCommunityCollectionsPagination.totalElements);
    }, [
        activeCommunityResourcesPagination,
        activeCommunityCollectionsPagination,
    ])

    return {
        totalResources,
        totalCollections,
    }
}
 
export default useCommunityStats;