import cloneDeep from "clone-deep";
import ResourceWizardActionTypes from "./action-types";
import ResourceWizardSteps from "../../entities/resource/constants/resource-wizard-steps";

const initialState = {
  inputLink: "",
  localResource: null,
  selectedTopics: [],
  selectedType: null,
  currentStep: ResourceWizardSteps.LINK_INPUT,
  imageFile: null,
  wizardEnabled: false,
  isAILoading: false,
  isAIFeedbackRecorded: false,
};

export const ResourceWizardReducer = (
  state = cloneDeep(initialState),
  action
) => {
  let newState = { ...state };
  switch (action.type) {
    case ResourceWizardActionTypes.SET_INPUT_LINK: {
      newState = {
        ...newState,
        inputLink: action.payload,
      };
      break;
    }
    case ResourceWizardActionTypes.ENABLE_WIZARD: {
      newState = {
        ...newState,
        wizardEnabled: true,
      };
      break;
    }
    case ResourceWizardActionTypes.SET_IMAGE: {
      newState = {
        ...newState,
        imageFile: action.payload,
      };
      break;
    }
    case ResourceWizardActionTypes.SET_LOCAL_RESOURCE: {
      newState = {
        ...newState,
        localResource: action.payload,
      };
      break;
    }
    case ResourceWizardActionTypes.SET_SELECTED_TOPICS: {
      newState = {
        ...newState,
        selectedTopics: action.payload,
      };
      break;
    }
    case ResourceWizardActionTypes.SET_SELECTED_TYPE: {
      newState = {
        ...newState,
        selectedType: action.payload,
      };
      break;
    }
    case ResourceWizardActionTypes.SET_CURRENT_STEP: {
      newState = {
        ...newState,
        currentStep: action.payload,
      };
      break;
    }
    case ResourceWizardActionTypes.SET_IS_AI_LOADING: {
      newState = {
        ...newState,
        isAILoading: action.payload,
      };
      break;
    }
    case ResourceWizardActionTypes.SET_IS_AI_FEEDBACK_RECORDED: {
      newState = {
        ...newState,
        isAIFeedbackRecorded: action.payload,
      };
      break;
    }
    case ResourceWizardActionTypes.RESET_STORE: {
      newState = cloneDeep(initialState);
      break;
    }
    default: {
    }
  }
  return newState;
};
