import React, { Component, useRef } from "react";
import CollectionCard from "../collection-card/collection-card";
import ICONS from "../../../../assets/quester-icons";
import { useSelector } from "react-redux";
import useCommunityStats from "../../../../shared/hooks/useCommunityStats";

const FeaturedCollectionsViewer = () => {
  const { activeCommunityCollections, isCollectionsLoaderActive } = useSelector(
    (state) => state.communityStore
  );

  const { totalCollections } = useCommunityStats();


  const ref = useRef(null);

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  if (isCollectionsLoaderActive) {
    return <></>;
  }

  return (
    <>
      <div className="collectionCardSliderWrapper">
        <div className="sectionHeader">
          <div className="title">Collections ({totalCollections})</div>
          <div className="arrows">
            <span className="prev" onClick={() => scroll(-120)}>
              {ICONS.chevronLeft}
            </span>
            <span className="next" onClick={() => scroll(120)}>
              {ICONS.chevronRight}
            </span>
          </div>
        </div>

        <div className="collectionCardsSlider" ref={ref}>
          {activeCommunityCollections?.map((collection, index) => {
            return <CollectionCard key={index} collection={collection} />;
          })}
        </div>
      </div>
    </>
  );
};

export default FeaturedCollectionsViewer;
