import React, { useState } from "react";

const DivWithScrollDirectionListener = ({
  children,
  onScrollUp = () => {},
  onScrollDown = () => {},
  onScroll = () => {},
  ...rest
}) => {
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const _onScroll = (e) => {
    const currentScrollTop = e.target.scrollTop;
    onScroll();
    if (currentScrollTop > lastScrollTop) {
      // Scrolling Down
      onScrollDown();
    } else if (currentScrollTop < lastScrollTop) {
      // Scrolling Up
      onScrollUp();
    }

    // Update the last scroll position to the current one
    setLastScrollTop(currentScrollTop);
  };

  return (
    <div onScroll={_onScroll} {...rest}>
      {children}
    </div>
  );
};

export default DivWithScrollDirectionListener;
