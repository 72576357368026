import cloneDeep from "clone-deep";
import CommunityStoreActionTypes from "./community-store-action-types";
import COMMUNITY_TABS from "../../constants/community-tabs";

const initialState = {
  activeCommunity: {},
  activeCommunityId: null,
  activeCommunityResources: [],
  activeCommunityResourcesPagination: {
    pageNo: 0,
    pageSize: 30,
    hasMore: true,
    totalSize: 0,
    totalElements: 0,
  },
  activeCommunityCollections: [],
  activeCommunityCollectionsPagination: {
    pageNo: 0,
    pageSize: 30,
    hasMore: true,
    totalSize: 0,
    totalElements: 0,
  },
  activeCommunityCollectionsSort: {
    sortField: "listname",
    sortOrder: "ASC",
  },
  activeTab: COMMUNITY_TABS.all,
  activeCommunityLabels: [],
  activeCommunityTypes: [],
  selectedCommunityLabels: [],
  selectedCommunityTypes: [],
  activeCommunityMembers: [],
  isCollectionsLoaderActive: true,
  isCommunityLoaderActive: true,
  isCommunityResourcesLoaderActive: true,
  isCommunityMembersLoaderActive: true,
};

export const CommunityStoreReducer = (
  state = cloneDeep(initialState),
  action
) => {
  let newState = { ...state };
  switch (action.type) {
    case CommunityStoreActionTypes.SET_ACTIVE_COMMUNITY: {
      newState = {
        ...newState,
        activeCommunity: action.payload,
      };
      break;
    }
    case CommunityStoreActionTypes.SET_ACTIVE_COMMUNITY_ID: {
      newState = {
        ...newState,
        activeCommunityId: action.payload,
      };
      break;
    }
    case CommunityStoreActionTypes.SET_ACTIVE_COMMUNITY_RESOURCES: {
      newState = {
        ...newState,
        activeCommunityResources: action.payload,
      };
      break;
    }
    case CommunityStoreActionTypes.SET_ACTIVE_COMMUNITY_RESOURCES_PAGINATION: {
      newState = {
        ...newState,
        activeCommunityResourcesPagination: {
          ...newState.activeCommunityResourcesPagination,
          ...action.payload,
        },
      };
      break;
    }
    case CommunityStoreActionTypes.SET_ACTIVE_COMMUNITY_COLLECTIONS: {
      newState = {
        ...newState,
        activeCommunityCollections: action.payload,
      };
      break;
    }
    case CommunityStoreActionTypes.SET_ACTIVE_COMMUNITY_MEMBERS: {
      newState = {
        ...newState,
        activeCommunityMembers: action.payload,
      };
      break;
    }
    case CommunityStoreActionTypes.CLEAR_ACTIVE_COMMUNITY_DATA: {
      newState = cloneDeep(initialState);
      break;
    }
    case CommunityStoreActionTypes.TOGGLE_COLLECTIONS_LOADER_STATUS: {
      newState = {
        ...newState,
        isCollectionsLoaderActive: action.payload,
      };
      break;
    }
    case CommunityStoreActionTypes.TOGGLE_COMMUNITY_LOADER_STATUS: {
      newState = {
        ...newState,
        isCommunityLoaderActive: action.payload,
      };
      break;
    }
    case CommunityStoreActionTypes.TOGGLE_COMMUNITY_RESOURCES_LOADER_STATUS: {
      newState = {
        ...newState,
        isCommunityResourcesLoaderActive: action.payload,
      };
      break;
    }
    case CommunityStoreActionTypes.TOGGLE_COMMUNITY_MEMBERS_LOADER_STATUS: {
      newState = {
        ...newState,
        isCommunityMembersLoaderActive: action.payload,
      };
      break;
    }
    case CommunityStoreActionTypes.SET_ACTIVE_COMMUNITY_LABELS: {
      newState = {
        ...newState,
        activeCommunityLabels: action.payload,
      };
      break;
    }
    case CommunityStoreActionTypes.SET_ACTIVE_COMMUNITY_TYPES: {
      newState = {
        ...newState,
        activeCommunityTypes: action.payload,
      };
      break;
    }
    case CommunityStoreActionTypes.SET_SELECTED_COMMUNITY_LABELS: {
      newState = {
        ...newState,
        selectedCommunityLabels: action.payload,
      };
      break;
    }
    case CommunityStoreActionTypes.SET_SELECTED_COMMUNITY_TYPES: {
      newState = {
        ...newState,
        selectedCommunityTypes: action.payload,
      };
      break;
    }
    // SET_ACTIVE_TAB
    case CommunityStoreActionTypes.SET_ACTIVE_TAB: {
      newState = {
        ...newState,
        activeTab: action.payload,
      };
      break;
    }
    case CommunityStoreActionTypes.SET_ACTIVE_COMMUNITY_COLLECTIONS_PAGINATION: {
      newState = {
        ...newState,
        activeCommunityCollectionsPagination: {
          ...newState.activeCommunityCollectionsPagination,
          ...action.payload,
        },
      };
      break;
    }
    case CommunityStoreActionTypes.SET_ACTIVE_COMMUNITY_COLLECTIONS_SORT: {
      newState = {
        ...newState,
        activeCommunityCollectionsSort: {
          ...newState.activeCommunityCollectionsSort,
          ...action.payload,
        },
      };
      break;
    }
    default: {
    }
  }
  return newState;
};
