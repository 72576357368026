import cloneDeep from "clone-deep";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useDeepCompareEffect from "use-deep-compare-effect";
import ICONS from "../../assets/quester-icons";
import Button from "../../components/buttons/buttons";
import FullscreenPopup from "../../components/fullscreen-popup/fullscreen-popup";
import Toggle from "../../components/toggle-button/toggle-button";
import ProfileEditor from "../../entities/user/components/profile-editor/profile-editor";
import ProfileImage from "../../entities/user/components/profile-image/profile-image";
import UserUtils from "../../entities/user/user-utils";
import router from "../../shared/top-level-declarations/history";
import "./user-settings-page.scss";
import ApiCalls from "../../shared/api";
import useKeyCloakSafe from "../../shared/hooks/useSafeKeycloak";
import { EventEmitter } from "../../shared/utils/event-emitter";
import SiteWiseEvents from "../../declarations/site-wise-events";

const UserSettingsPage = () => {
  const { keycloak, initialized } = useKeyCloakSafe();

  const [profileEditView, setProfileEditView] = useState(false);
  const [showNewsletter, setShowNewsletter] = useState(false);
  const [billingPortalUrl, setBillingPortalUrl] = useState("");

  const { profileData } = useSelector((state) => state.userStore);
  const [emailComm, setEmailComm] = useState(false);
  const _updateEmailComm = (emailComm) => {
    const payload = cloneDeep(profileData);
    payload.allowEmailComm = emailComm;
    setEmailComm(emailComm);
    UserUtils.updateProfile(payload);
  };

  const _inviteBotToDiscord = () => {
    const url = `https://discord.com/api/oauth2/authorize?client_id=${window.REACT_APP_DISCORD_APP_ID}&permissions=2147552256&scope=bot%20applications.commands`;
    window.open(url, "_blank").focus();
  };

  const _openChromeExtension = () => {
    window
      .open(
        "https://chromewebstore.google.com/detail/quester-the-best-way-to-s/jgpeeeiofnklekkionlnnclifmgjechi?hl=en-GB",
        "_blank"
      )
      .focus();
  };

  const _optInEmail = (e) => {
    // setOptInToggleActive(true);
    setShowNewsletter(false);
  };

  const _optOutEmail = (e) => {
    setEmailComm(false);
    setShowNewsletter(false);
    _updateEmailComm(false);
  };

  const _onToggleBtnClick = (e) => {
    // If it's active, show the popup first
    if (emailComm) {
      // setShowNewsletter(true);
      EventEmitter.dispatch(SiteWiseEvents.SHOW_EMAIL_OPT_IN_POPUP);
    } else {
      setEmailComm(true);
      // onOptInChange(true);
      _updateEmailComm(true);
    }
  };

  const _fetchBillingPortalUrl = async () => {
    try {
      const currentUrlWithoutQuery = window.location.href.split("?")[0];
      const { data } = await ApiCalls.subscripion.private.getBillingPortalUrl(
        currentUrlWithoutQuery
      );
      if (data?.url?.length) {
        setBillingPortalUrl(data.url);
      }
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  useDeepCompareEffect(() => {
    if (profileData?.allowEmailComm !== undefined) {
      setEmailComm(profileData?.allowEmailComm);
    }
  }, [profileData]);

  useDeepCompareEffect(() => {
    if (keycloak?.authenticated && initialized) {
      _fetchBillingPortalUrl();
    }
  }, [keycloak, initialized]);

  return (
    <>
      {profileData?.uuid ? (
        <>
          <div className="mainContentWrapper">
            <div className="user-settings-page">
              <div className="heroBanner">
                <div className="container">
                  <div className="heroInner">
                    <div className="headline">My settings</div>
                    <div className="subtitle">
                      Change your user name, profile picture, notifications,
                      integrations...{" "}
                    </div>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="userSettingsContent">
                  <div className="section">
                    <div className="left"></div>
                    <div className="right">
                      <div className="backButton">
                        <Button
                          size={"small"}
                          icon={ICONS.arrowLeft}
                          iconPos={"left"}
                          type={"default"}
                          text={"Back"}
                          onClick={(e) => router.navigate("/my-profile")}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="section editUserProfile">
                    <div className="left">
                      <span>User profile</span>
                    </div>

                    <div className="right">
                      {profileEditView ? (
                        <ProfileEditor
                          onClose={(e) => setProfileEditView(false)}
                        />
                      ) : (
                        <div className="contentWrapper profileView">
                          <div className="top">
                            <div className="userImage">
                              <ProfileImage path={profileData?.photoUrl} />
                            </div>
                            <div className="userDetails">
                              <div className="username">
                                <label>User Name:</label>
                                <div className="text">
                                  {profileData?.displayName?.length
                                    ? profileData?.displayName
                                    : profileData?.owningUser}
                                </div>
                              </div>
                              <div className="description">
                                <label>Description:</label>
                                <div className="text">
                                  {profileData?.bio || ""}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="action">
                            <div
                              className="inline-block"
                              onClick={(e) => setProfileEditView(true)}
                            >
                              <Button
                                size={"extraSmall"}
                                icon={ICONS.edit}
                                iconPos={"left"}
                                type={"default"}
                                text={"Edit"}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="section editNotification">
                    <div className="left">
                      <span>Notifications</span>
                    </div>

                    <div className="right">
                      <div className="contentWrapper">
                        <div className="newsletterCheckbox">
                          <label>Receive emails from quester</label>

                          <div className="toggleWrapper">
                            <Toggle
                              active={emailComm}
                              onChange={_onToggleBtnClick}
                            />
                          </div>
                        </div>

                        <div className="notes">
                          Note: Your information will not be shared outside of
                          quester without your permission. Read our{" "}
                          <span onClick={(e) => router.navigate("/privacy")}>
                            privacy policy
                          </span>
                          .
                        </div>
                      </div>
                    </div>
                  </div>
                  {billingPortalUrl?.length ? (
                    <>
                      <div className="section editMemberships">
                        <div className="left">
                          <span>Memberships</span>
                        </div>

                        <div className="right">
                          <div className="contentWrapper">
                            <div className="titleText">
                              You can manage your subscription and invoicing in
                              the following link:
                            </div>
                            <Button
                              size={"medium"}
                              icon={ICONS.newTab}
                              iconPos={"right"}
                              type={"contrast"}
                              text={"Manage membership"}
                              onClick={(e) => {
                                window.open(billingPortalUrl, "_blank").focus();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div className="section editIntegrations">
                    <div className="left">
                      <span>Integrations</span>
                    </div>

                    <div className="right">
                      <div className="contentWrapper">
                        <div className="titleText">
                          You can integrate your account with third-party
                          services for an enhance experience. More integrations
                          will be available soon.
                        </div>

                        <div className="appSectionWrapper">
                          <div className="appSectionLabel">
                            Discord link bot
                          </div>
                          <div className="appSection">
                            <div className="appLeft">
                              <div className="imageWrapper">
                                {ICONS.discordLogoFull}
                              </div>
                              <Button
                                icon={ICONS.plus}
                                size={"small"}
                                iconPos={"left"}
                                text={"Connect"}
                                type={"contrast"}
                                onClick={_inviteBotToDiscord}
                              />
                            </div>

                            <div className="appRight disabled">
                              <div className="point">
                                <label>Account:</label>
                                <div className="text">Name</div>
                              </div>
                              <div className="point">
                                <label>API Key:</label>
                                <div className="text">
                                  Integrate your Discord account to get an API
                                  Key.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="appSectionWrapper">
                          <div className="appSectionLabel">
                            Chrome Extension
                          </div>
                          <div className="appSection">
                            <div className="appLeft">
                              <div className="imageWrapper">
                                {ICONS.chromeIcon}
                              </div>
                              <Button
                                icon={ICONS.plus}
                                size={"small"}
                                iconPos={"left"}
                                text={"Install"}
                                type={"contrast"}
                                onClick={_openChromeExtension}
                              />
                            </div>

                            <div className="appRight">
                              <p>
                                Create aesthetically pleasing, social, and
                                incredibly user-friendly databases specifically
                                designed for opinions.
                              </p>
                              <p>
                                From videos and articles to PDFs and web pages,
                                this tool allows you to capture and share a wide
                                range of resources with your quester community.
                                Additionally, you can gather opinions and votes
                                on these resources, enriching the collaborative
                                experience.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="section deleteAccount">
                                <div className="left"><span>Delete Account</span></div>

                                <div className="right">
                                    <div className="contentWrapper">
                                        <div className="text"> Once you delete your account, there is no going back! 🙀 </div>

                                        <div className="deleteButtonWrapper">
                                            <div className="deleteButton"> Delete Account </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                </div>
              </div>
            </div>
          </div>
          <FullscreenPopup
            headerTitle="👋 Hey there!"
            // headerImage={require("../assets/images/cookie.png")}
            maxHeight="600px"
            maxWidth="360px"
            successBtnIcon={ICONS.checkCircle}
            successBtnText="Sure, I don't mind being emailed"
            // cancelBtnIcon={ICONS.close}
            cancelBtnText="No, thanks!"
            isActive={showNewsletter}
            onClose={_optOutEmail}
            onConfirm={_optInEmail}
            extraClass="newsletterPopup"
          >
            <div className="textInner">
              <p>
                First of all, a heartfelt thank you for joining and using
                quester 💞. We're working hard to make quester as valuable as
                possible for people like yourself.
              </p>
              <p>
                To help us do that, would you consider allowing us to send you
                the occasional email? These would include opportunities for your
                feedback, along with occasional updates, tips, and insights.
              </p>
              <p>Your voice matters, and we're all ears.</p>
              <div className="questerSignature">{ICONS.questerSignature}</div>
            </div>
          </FullscreenPopup>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default UserSettingsPage;
