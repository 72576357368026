import React, { Component, useEffect, useState } from "react";
import ICONS from "../../../../assets/quester-icons";
import Button from "../../../../components/buttons/buttons";
import ResourceUtils from "../../resource-utils";
import Spinner from "../../../../components/core/spinner/spinner";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useSelector } from "react-redux";
import ResourceWizardSteps from "../../constants/resource-wizard-steps";
import ResourceWizardMobileButton from "../resource-wizard-mobile-buttons/resource-wizard-mobile-buttons";

const ResourceLinkInputStep = ({ isAi = true }) => {
  const [showSendButton, setShowSendButton] = useState(false);
  const { localResource } = useSelector((state) => state.resourceWizardStore);
  const { activeCommunityId, activeCommunityLabels, activeCommunityTypes } =
    useSelector((state) => state.communityStore);
  const [link, setLink] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const _submitLink = async () => {
    if (!link?.length) {
      setErrorMessage("Oops! URL is not valid. Please, try with another one.");
      return;
    }
    if (link === localResource?.sourceUrl) {
      ResourceUtils.switchStep(ResourceWizardSteps.PREVIEW_WITH_TOPIC_EDIT);
      return;
    }
    setErrorMessage("");
    let isValidLink = ResourceUtils.checkIfValidUrl(link);
    if (!isValidLink) {
      setErrorMessage("Oops! URL is not valid. Please, try with another one.");
      return;
    }
    const safeLink = ResourceUtils.prepareSafeLink(link);
    setLoading(true);
    const resource = await ResourceUtils.generateResourceFromLink(safeLink);
    if (!resource) {
      setErrorMessage("We couldn't fetch the resource. Please try again.");
      return;
    }
    const commaSeperatedTopics = activeCommunityLabels
      .map((label) => label.displayName)
      .join(",");
    const commaSeperatedTypes = activeCommunityTypes
      .map((type) => type.description)
      .join(",");
    await ResourceUtils.generateTypeAndTopicsFromAI(
      link,
      commaSeperatedTopics,
      commaSeperatedTypes
    );
    setLoading(false);
    ResourceUtils.setLocalResource(resource);
    ResourceUtils.switchStep(ResourceWizardSteps.PREVIEW_WITH_TOPIC_EDIT);
  };

  useDeepCompareEffect(() => {
    const { sourceUrl } = { ...localResource };
    setLink(sourceUrl);
  }, [localResource || {}]);

  return (
    <>
      <div className="innerWrapper">
        <div className="section">
          <div
            className={
              "sectionInnerWrapper step1 addUrl d-grid" + (isAi ? " ai " : "")
            }
          >
            <div
              className="closeIcon"
              onClick={(e) => ResourceUtils.resetWizard()}
            >
              {ICONS.crossIcon}
            </div>{" "}
            {/* this div will change only */}
            <div className="info">
              <div className="stepTitle">
                📥 Let's add a new recommendation!
              </div>
              <div className="stepDesc">
                {isAi ? (
                  <>
                    <div></div>
                    <div className="aiAssistedText">
                      {ICONS.sparkleFilledGradient} <span>AI assisted</span>
                    </div>
                  </>
                ) : (
                  <div class="mainText">
                    We will guide you through the steps to upload this resource
                    so everyone can enjoy it!
                  </div>
                )}
              </div>
            </div>
            <div
              className={"editableInputArea " + (errorMessage ? "error" : "")}
            >
              <div className="imageWrapper">
                <img
                  src={require("../../../../assets/images/thumbnail.png")}
                  alt=""
                />
              </div>

              <div className="right">
                <div className="inputWrapper">
                  <span className="icon">{ICONS.paperclip}</span>
                  <input
                    type="text"
                    className={
                      "form-control big " + (showSendButton ? "active" : "")
                    }
                    placeholder="Paste here the URL you want to add"
                    onClick={(e) => setShowSendButton(true)}
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                    style={
                      loading ? { pointerEvents: "none", opacity: "0.8" } : {}
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        _submitLink();
                      }
                    }}
                  />
                  <div
                    className={
                      "sendButton " +
                      (showSendButton ? "d-none d-md-flex" : "d-none")
                    }
                  >
                    {!loading && link?.length ? (
                      <Button
                        // icon={ICONS.arrowRight}
                        // iconPos={"right"}
                        text={"Add"}
                        size={"extraSmall"}
                        type={"contrast"}
                        onClick={(e) => {
                          _submitLink();
                        }}
                      />
                    ) : (
                      <></>
                    )}
                    {loading ? (
                      <Spinner withContainer={false} loaderSize="16px" />
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="pasteForMobile">{ICONS.clipboard}</div>
                </div>
                {errorMessage?.length ? (
                  <div className="errorText">{errorMessage}</div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ResourceWizardMobileButton
        stepNumber={1}
        buttonText={loading ? "Loading..." : "Next"}
        buttonType={loading ? "disabled" : "contrast"}
        onClick={() => _submitLink()}
      />
    </>
  );
};

export default ResourceLinkInputStep;
