import React, { Component } from "react";
import CommunityItem from "../../../../components/community-item/community-item";
import "./sponsor-resource.scss";

const SponsorResource = () => {
  // {
  //   uuid: "PyF3MKlzASI_core",
  //   name: "core",
  //   uniqueName: "core",
  //   displayName: "Core",
  // },
  return (
    <>
      <CommunityItem
        communityItem={{
          uuid: "9bgot5cquz3",
          sourceUrl: "https://shepherd.study/?source=quester",
          title: "Upload your notes and become an expert in minutes",
          creator: null,
          imageURL: require("../../../../assets/images/sponsor-white.png"),
          typeId: "rGtiesmTnUa",
          typeName: "IB Core",
          typeColor: null,
          saveCount: 353,
          commentCount: 0,
          versionNumber: 0,
          labels: [
            {
              uuid: "static1",
              uniqueName: "AI study tool",
              displayName: "AI study tool",
              name: "AI study tool",
            },
            {
              uuid: "static2",
              uniqueName: "Create quizzes",
              displayName: "Create quizzes",
              name: "Create quizzes",
            },
          ],
          isFollowed: false,
          questItemId: null,
          myDRow: null,
          createdOn: "2023-08-25T16:36:27.652+00:00",
          photoUrl: null,
          ownerId: "sharkz",
          displayName: null,
          myResourceId: null,
          stat: {},
          isPremium: null,
          drowStats: {},
          dtableSchema: {},
          _voteStat: [],
        }}
        teamResource={true}
        type={"sponsor"}
      />
    </>
  );
};

export default SponsorResource;
