import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ICONS from "../../../../assets/quester-icons";
import MobileDrawer from "../../../../components/mobile-drawer/mobile-drawer";
import SiteWiseEvents from "../../../../declarations/site-wise-events";
import ApiCalls from "../../../../shared/api";
import AuthHelper from "../../../../shared/helper-methods/auth-helper";
import { EventEmitter } from "../../../../shared/utils/event-emitter";
import MetaUtils from "../../../../shared/utils/meta-utils";
import "./subscription-popup.scss";
import CommunityUtils from "../../../../entities/community/community-utils";

const stripePromise = loadStripe(window.REACT_APP_STRIPE_PUBLIC_KEY);

const SubscriptionPopupInner = ({ isVisble = true, onDismiss }) => {
  const [sessionId, setSessionId] = useState();
  const [clientSecret, setClientSecret] = useState(null);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [isPaymentError, setIsPaymentError] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const { activeCommunityId } = useSelector((state) => state.communityStore);
  // const stripe = useStripe();

  const _initiatePayment = async () => {
    if (!AuthHelper.isLoggedIn()) {
      MetaUtils.saveActionForNextAppLoad({
        type: "triggerEvent",
        expiresAt: moment().add(5, "mins").toDate(),
        payload: {
          event: SiteWiseEvents.showSubscriptionPopup,
        },
      });
      AuthHelper.login();
      return;
    }
    setIsPaymentLoading(true);
    setIsPaymentModalOpen(true);
    try {
      const {
        data: { sessionId, clientSecret },
      } = await ApiCalls.subscripion.private.getStripeSessionId({
        owningOrganizationId: activeCommunityId,
        priceId: window.REACT_APP_STRIPE_PRICE_ID,
        successUrl: `${window.location.href}?paymentSuccess=true&community=${activeCommunityId}`,
        cancelUrl: `${window.location.href}?paymentCancel=true&community=${activeCommunityId}`,
      });
      // redirect to stripe
      const stripe = await stripePromise;
      stripe.redirectToCheckout({ sessionId });
      return;
      setSessionId(sessionId);
      setClientSecret(clientSecret);
    } catch (error) {
      console.log("error :>> ", error);
    }
    setIsPaymentLoading(false);
  };

  const handleComplete = () => {};

  return (
    <div className="premiumContentBuyModal">
      <div className="overlay" onClick={onDismiss}></div>
      <div className="inner">
        {isPaymentModalOpen ? (
          <>
            {isPaymentLoading ? (
              <h3>Loading</h3>
            ) : (
              <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={{
                  clientSecret,
                  onComplete: handleComplete,
                }}
              >
                <EmbeddedCheckout />
              </EmbeddedCheckoutProvider>
              // <Elements stripe={stripePromise} options={{
              //   clientSecret
              // }}>
              //   <CheckoutForm />
              // </Elements>
            )}
          </>
        ) : (
          <>
            <div className="bgDesign">
              <img
                src={require("../../../../assets/images/confetti.png")}
                alt=""
              />
            </div>

            <div className="left">
              <div className="titleSection">
                <div className="bigTitle">IB Students and Tutors Premium</div>
                <div className="bigDesc">
                  Exclusive questions and notes by actual IB examiners and
                  subject experts for the 2025 syllabus
                </div>
              </div>

              <div className="benefits">
                <div className="benefit">
                  <div className="icon">{ICONS.student}</div>
                  <div className="texts">
                    <div className="label">Unlock Exclusive Chats</div>
                    <div className="desc">
                      24/7 access to IB experts (all with 40+ scores) that will
                      answer any questions you have about IB - guaranteed
                      response within a day
                    </div>
                  </div>
                </div>

                <div className="benefit">
                  <div className="icon">{ICONS.diamond}</div>
                  <div className="texts">
                    <div className="label">Unlock Exclusive Content</div>
                    <div className="desc">
                      Access Chemistry realistic exam-like questions and
                      extensive notes written by <strong>actual</strong> IB
                      examiners <strong>Only available on quester</strong>
                    </div>
                  </div>
                </div>

                <div className="benefit">
                  <div className="icon">{ICONS.globeStand}</div>
                  <div className="texts">
                    <div className="label">Reward the Community</div>
                    <div className="desc">
                      Revenue is shared with the content creators, and you get a
                      lovely little badge by your name. You absolute legend,
                      you.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="right">
              <div className="titleSection">
                <div className="bigTitle">IB Students and Tutors Premium</div>
                <div className="bigDesc">
                  Exclusive questions and notes by actual IB examiners and
                  subject experts for the 2025 syllabus
                </div>
              </div>

              <div className="pricingTag">
                <div className="userImage">
                  <img
                    src={require("../../../../assets/images/thumbnail.png")}
                    alt=""
                  />
                </div>
                <div className="price">$9.99/mo</div>
                <div className="small">Premium member</div>
              </div>

              <div className="premiumButton" onClick={_initiatePayment}>
                <div className="btnInner">
                  <span>Become a Premium Member</span>
                </div>
              </div>

              <div className="cancelAnytime">Cancel anytime</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const SubscriptionPopup = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const { activeCommunityId } = useSelector((state) => state.communityStore);

  const _listenToEvent = (e) => {
    EventEmitter.subscribe(SiteWiseEvents.showSubscriptionPopup, () => {
      setIsVisible(true);
    });
  };

  useEffect(() => {
    _listenToEvent();
    return () => {
      EventEmitter.cancelAll(SiteWiseEvents.showSubscriptionPopup);
    };
  }, []);

  const hasPurchased = CommunityUtils.checkIfSubscribed();
  

  if (isVisible && !hasPurchased) {
    return (
      <>
        <SubscriptionPopupInner onDismiss={() => setIsVisible(false)} />
        <MobileDrawer
          isActive={true}
          headerTitle="Become a premium user"
          headerIcon={ICONS.diamond}
          extraClass="premiumContentBuyDrawer"
          onClose={() => setIsVisible(false)}
        >
          <SubscriptionPopupInner onDismiss={() => setIsVisible(false)} />
        </MobileDrawer>
      </>
    );
  } else {
    return <></>;
  }
};

export default SubscriptionPopup;
