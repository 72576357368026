/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation, useParams } from "react-router-dom";
import Spinner from "../components/core/spinner/spinner";
import DiscoveryBar from "../components/discover-bar/discovery-bar";
import CommunityUtils from "../entities/community/community-utils";
import CommunityFilters from "../entities/community/components/community-filters/community-filters";
import CommunityInfoViewer from "../entities/community/components/community-info-viewer/community-info-viewer";
import CommunityResourcesViewer from "../entities/community/components/community-resources-viewer/community-resources-viewer";
import useKeyCloakSafe from "../shared/hooks/useSafeKeycloak";
import ActivityRecorder from "../shared/utils/activity-recorder";
import Swal from "sweetalert2";
import { Player } from "@lottiefiles/react-lottie-player";

import "./communities-page.scss";
import CommunityCollectionPage from "./community-collection-page";
import SubscriptionPopup from "../features/subscription/components/subscription-popup/subscription-popup";
import CommentBar from "../components/comment-bar/comment-bar";
import QuestComments from "../features/quest-comments/components/quest-comments/quest-comments";
import CommunityComments from "../features/quest-comments/components/community-comments/community-comments";
import CommunityCommentsHelper from "../features/quest-comments/community-comments-helper";
import DivWithScrollDirectionListener from "../components/div-with-scroll-direction-listner/div-with-scroll-direction-listner";
import GeneralHelper from "../shared/helper-methods/general-helpers";
import CommunityTabView from "../entities/community/components/community-tab-view/community-tab-view";
import COMMUNITY_TABS from "../constants/community-tabs";
import FeaturedCollectionsViewer from "../entities/collection/components/featured-collections-viewer/featured-collections-viewer";
import CollectionsGridViewer from "../entities/collection/components/collections-grid-viewer/collections-grid-viewer";
import ResourceWizard from "../entities/resource/components/resource-wizard/resource-wizard";

const CommunityPage = () => {
  const { keycloak, initialized } = useKeyCloakSafe();
  // Check on community id change in route
  const location = useLocation();

  let { communityId } = useParams();

  const {
    isCommunityLoaderActive,
    activeCommunityResourcesPagination,
    activeCommunityCollectionsPagination,
    isCollectionsLoaderActive,
    activeCommunity,
    activeTab = COMMUNITY_TABS.all,
  } = useSelector((state) => state.communityStore);

  const _onLoadMore = () => {
    if (activeTab !== COMMUNITY_TABS.collections) {
      CommunityUtils.loadNextPageCommunityResources();
    } else {
      CommunityUtils.loadNextPageCommunityCollections();
    }
  };

  useEffect(() => {
    // On load
    // Get community id from url (/c/:id)
    if (initialized) {
      CommunityUtils.initiateCommunityLoad(communityId);
      CommunityCommentsHelper.initiateCommentLoad(communityId);
    }
  }, [keycloak, initialized, location]);

  useEffect(() => {
    _scrollToTop();
  }, [activeTab]);

  const [infiniteRef, { rootRef }] = useInfiniteScroll({
    loading: isCollectionsLoaderActive,
    hasNextPage: activeCommunityResourcesPagination.hasMore,
    onLoadMore: _onLoadMore,
    disabled: false,
    rootMargin: "0px 0px 1800px 0px",
  });

  const _scrollToTop = () => {
    //rootRef
    console.log("rootRef", rootRef.current);

    // Scroll using class selector
    const element = document.querySelector(".communityPageMainContent");
    if (element) {
      element.scrollTop = 0;
    }
  };

  const hasMore =
    activeTab !== COMMUNITY_TABS.collections
      ? activeCommunityResourcesPagination.hasMore
      : activeCommunityCollectionsPagination.hasMore;

  const _renderTabContent = () => {
    switch (activeTab) {
      case COMMUNITY_TABS.all: {
        return (
          <>
            <FeaturedCollectionsViewer />
            <CommunityResourcesViewer />
          </>
        );
      }
      case COMMUNITY_TABS.collections: {
        return (
          <>
            <CollectionsGridViewer />
          </>
        );
      }
      case COMMUNITY_TABS.recommendation: {
        return (
          <>
            <CommunityResourcesViewer />
          </>
        );
      }
      default: {
        return <></>;
      }
    }
  };

  useEffect(() => {
    if (activeCommunity?.uuid) {
      ActivityRecorder.startRecordingPageActivity({
        pageName: `CommunityPage-${activeCommunity.organisationDisplayName}`,
      });
    }
    return () => {
      ActivityRecorder.stopRecordingPageActivity();
    };
  }, [activeCommunity?.uuid]);

  return (
    <>
      {isCommunityLoaderActive ? (
        <></>
      ) : (
        <>
          <DivWithScrollDirectionListener
            className="communityPageMainContent"
            onScrollDown={() => {
              GeneralHelper.updateMobileFooterOnScrollDown();
              document.body.classList.add("communityContentTabsSticky"); // dada ekhane
            }}
            onScrollUp={() => {
              GeneralHelper.updateMobileFooterOnScrollUp();
              document.body.classList.remove("communityContentTabsSticky"); // dada ekhane
            }}
            ref={rootRef}
          >
            <CommunityInfoViewer />
            {/* desktop */}
            <div className="addNewCollectionMegaWrap d-none d-md-block">
              {/* <AddNewCollection /> */}
              <ResourceWizard />
            </div>
            <CommunityFilters />
            <CommunityTabView />
            {_renderTabContent()}
            {hasMore ? (
              <>
                <div
                  className="loading"
                  ref={infiniteRef}
                  style={{
                    height: "100px",
                    textAlign: "center",
                    marginBottom: "10vh",
                    marginTop: "4vh",
                  }}
                >
                  <Spinner withContainer={false} />
                </div>
              </>
            ) : (
              <></>
            )}
          </DivWithScrollDirectionListener>
          <CommunityComments />
        </>
      )}
    </>
  );
};

const CommunityLayout = () => {
  const { communityId } = useParams();
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);

  const _keepCheckingForSubscriptionSuccess = () => {
    const checkInterval = setInterval(async () => {
      const hasSubscribed = await CommunityUtils.checkIfSubscribedFromServer(); // Call the API or function checking the payment status
      if (hasSubscribed) {
        clearInterval(checkInterval); // Stop checking once payment is confirmed
        setIsPaymentProcessing(false);
        setIsPaymentSuccess(true);
        // Reload page with same url without query params
        window.location.href = window.location.href.split("?")[0];
      }
    }, 1000); // Check every second, adjust this interval as needed
  };

  const _showSubscriptionSuccess = () => {
    setIsPaymentProcessing(true);
    _keepCheckingForSubscriptionSuccess();
  };

  const _checkForSubscriptionSuccess = () => {
    if (window.location.search.includes("paymentSuccess=true")) {
      _showSubscriptionSuccess();
    }
  };

  useEffect(() => {
    _checkForSubscriptionSuccess();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots.length >= 3) {
          return "";
        } else {
          return prevDots + ".";
        }
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);
  const [dots, setDots] = useState("");

  return (
    <>
      <div className="mainContentWrapper">
        <div className="community-page collections-page">
          <DiscoveryBar open={true} />

          <Routes>
            <Route path="q/:questId" element={<CommunityCollectionPage />} />
            <Route path="*" element={<CommunityPage />} />
          </Routes>
        </div>
      </div>
      <SubscriptionPopup />
      {/*************************************************** /MAIN CONTENT */}

      {/*************************************************** PLEASE WAIT POPUP */}
      {isPaymentProcessing ? (
        <div className="paymentSuccessPopupWrapper">
          <div className="paymentSuccessPopup">
            <div className="bgDesign">
              <img src={require("../assets/images/confetti.png")} />
            </div>

            <Player
              src={require("../assets/animations/loading.json")}
              className="paymentSuccessAnimation"
              loop={true}
              autoplay={true}
            />
            <div className="title">Please wait {dots}</div>
            <div className="desc">We are processing your payment</div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/*************************************************** /PLEASE WAIT POPUP */}

      {/*************************************************** SUCCESS POPUP */}
      {isPaymentSuccess ? (
        <div className="paymentSuccessPopupWrapper">
          <div className="paymentSuccessPopup">
            <div className="bgDesign">
              <img src={require("../assets/images/confetti.png")} />
            </div>

            <Player
              src={require("../assets/animations/successTick.json")}
              className="paymentSuccessAnimation"
              loop={true}
              autoplay={true}
            />
            <div className="title">Payment successful</div>
            <div className="desc">
              You have successfully subscribed to this community
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/*************************************************** SUCCESS POPUP */}
    </>
  );
};

export default CommunityLayout;
