import React, { Component, useEffect, useState } from "react";
import Image from "../../../../components/core/image/image";

const CollectionIcon = ({ collectionImageLink, ...rest }) => {
  const [thumbnail, setThumbnail] = useState(
    "https://t4.ftcdn.net/jpg/00/93/85/69/360_F_93856984_YszdhleLIiJzQG9L9pSGDCIvNu5GEWCc.jpg"
  );
  const _generateThumbnail = () => {
    // First set default thumbnail
    let thumbnail = "https://quester.io/pwa-icons/icon-512x512.png";
    // If quest has a thumbnail, use that
    if (collectionImageLink?.length) {
      // Check if has http or https
      if (collectionImageLink.indexOf("http") > -1) {
        thumbnail = collectionImageLink;
      } else {
        thumbnail = `${window.REACT_APP_CDN_BASE_URL}${collectionImageLink}`;
      }
    }
    setThumbnail(thumbnail);
  };

  useEffect(() => {
    _generateThumbnail();
  }, [collectionImageLink]);
  return <Image src={thumbnail} {...rest} />;
};

export default CollectionIcon;
