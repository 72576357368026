import React, { Component, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SponsorUtils from "../../sponsor-utils";

const SponsorHeader = () => {
  const location = useLocation();
  const [isSponosored, setIsSponsored] = useState(false);

  useEffect(() => {
    const isSponsored = SponsorUtils.checkIfSponsorCommunity();
    setIsSponsored(isSponsored);
  }, [location]);

  if (!isSponosored) {
    return <></>;
  } else {
    return (
      <div className="sponsoredFly"
        onClick={e => {
            e.preventDefault();
            SponsorUtils.onSponsorLinkClick("header");
        }}
      >
        <span>supported by</span>
        <img src={require("../../../../assets/images/sponsor_long.png")} alt="" />
      </div>
    );
  }
};

export default SponsorHeader;
