import React, { Component, useState } from "react";
import ICONS from "../../../../assets/quester-icons";
import ResourceUtils from "../../resource-utils";

const AIAssitanceFeedback = () => {
  const [isFeedbackRecorded, setIsFeedbackRecorded] = useState(false);

  const _recordFeedback = (e, feedback) => {
    setIsFeedbackRecorded(true);
    ResourceUtils.recordFeedback(feedback);
  };

  return (
    <div className="helpImprove">
      {isFeedbackRecorded ? (
        <>
          <span>Thank you for your feedback!</span>
        </>
      ) : (
        <>
          <span>Help us to improve. How was the result? </span>

          <div className="buttons">
            <span onClick={(e) => _recordFeedback(e, "bad")}>
              {ICONS.thumbsDownNew}
            </span>
            <span onClick={(e) => _recordFeedback(e, "good")}>
              {ICONS.thumbsUpNew}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default AIAssitanceFeedback;
