import React, { Component, useState, useRef, useEffect } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import ICONS from "../../assets/quester-icons";
import { Player } from "@lottiefiles/react-lottie-player";
import Button from "../../components/buttons/buttons";
import SideBar from "../../components/sidebar/sidebar";
import "./home-page.scss";
import router from "../../shared/top-level-declarations/history";
import AuthHelper from "../../shared/helper-methods/auth-helper";
import ApiCalls from "../../shared/api";
import useKeyCloakSafe from "../../shared/hooks/useSafeKeycloak";
import CommunityUtils from "../../entities/community/community-utils";
import Image from "../../components/core/image/image";
import CommunityIcon from "../../entities/community/components/community-icon/community-icon";
import ActivityRecorder from "../../shared/utils/activity-recorder";
import GeneralHelper from "../../shared/helper-methods/general-helpers";
import DivWithScrollDirectionListener from "../../components/div-with-scroll-direction-listner/div-with-scroll-direction-listner";

const CommunityCard = ({
  community: {
    uuid,
    organisationDisplayName,
    orgDescription,
    orgIconImageURL,
    bannerImageURI,
    displayOrder,
    categories,
    membersCount,
    roles,
  },
}) => {
  const [isJoining, setIsJoining] = useState(false);
  const _onClick = (e) => {
    router.navigate(`/c/${uuid}`);
  };
  const _onJoinClick = async (e) => {
    e.stopPropagation();
    if (AuthHelper.isLoggedIn()) {
      try {
        setIsJoining(true);
        await ApiCalls.org.private.joinOrg(uuid);
        await GeneralHelper.sleep(1000);
        setIsJoining(false);
        router.navigate(`/c/${uuid}`);
      } catch (error) {
        console.log("error :>> ", error);
      }
    } else {
      AuthHelper.login();
    }
  };

  return (
    <div className="communityCard" onClick={_onClick}>
      <div className="innerWrapper">
        <div className="topSection">
          <div className="coverImage">
            <img
              src={
                bannerImageURI?.length
                  ? bannerImageURI
                  : require("../../assets/images/cover-pic.png")
              }
              alt=""
            />
          </div>
          <div className="dpImage">
            {/* <img
              src={
                orgIconImageURL?.length
                  ? orgIconImageURL
                  : require("../../assets/images/sushi.png")
              }
              alt=""
            /> */}
            <CommunityIcon orgIconImageURL={orgIconImageURL} />
          </div>
        </div>

        <div className="middleSection">
          <div className="communityName" title={organisationDisplayName}>
            {organisationDisplayName}
          </div>

          <div className="communityDesc" title={orgDescription}>
            {orgDescription}
          </div>
        </div>

        <div className="bottomSection">
          <div className="buttons">
            <div className="members">
              <span className="number">{membersCount}</span>
              {ICONS.usersCommunity}
            </div>
            <Button
              onClick={_onJoinClick}
              disabled={roles?.length || isJoining}
              size={"extraSmall"}
              // icon={ICONS.plus}
              // iconPos={"left"}
              type={"default"}
              text={isJoining ? "Joining" : roles?.length ? "Joined" : "Join"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const HomePage = () => {
  const { keycloak, initialized } = useKeyCloakSafe();

  const [communities, setCommunities] = useState([]);

  const _fetchCommunities = async () => {
    let fetchedCommunities = [];
    if (initialized) {
      if (keycloak?.authenticated) {
        const { data } = await ApiCalls.org.private.fetchOrgs();
        if (data?.organisations?.length) {
          fetchedCommunities = data.organisations;
        }
      } else {
        const { data } = await ApiCalls.org.public.fetchOrgs();
        if (data?.organisations?.length) {
          fetchedCommunities = data.organisations;
        }
      }
      // Hide community with id "xYyMG49YiBE"
      fetchedCommunities = fetchedCommunities.filter(
        (org) => org.uuid !== "xYyMG49YiBE"
      );
      const sortedCommunities =
        CommunityUtils.sortHomePageCommunities(fetchedCommunities);
      setCommunities(sortedCommunities);
    }
  };

  useEffect(() => {
    CommunityUtils.clearStore();
    _fetchCommunities();
  }, [keycloak, initialized]);

  useEffect(() => {
    ActivityRecorder.startRecordingPageActivity({
      pageName: "HomePage",
    });
    return () => {
      ActivityRecorder.stopRecordingPageActivity();
    };
  }, []);

  return (
    <>
      {/*************************************************** SIDEBAR */}
      {/* <SideBar /> */}
      {/*************************************************** /SIDEBAR */}

      {/*************************************************** MAIN CONTENT */}
      <div className="mainContentWrapper">
        <DivWithScrollDirectionListener
          className="home-page"
          onScrollDown={() => {
            GeneralHelper.updateMobileFooterOnScrollDown();
          }}
          onScrollUp={() => {
            GeneralHelper.updateMobileFooterOnScrollUp();
          }}
        >
          <div className="heroBanner">
            <div className="heroInner">
              <div className="headline">
                Recommendations you trust, <br />
                from communities you love.
              </div>
              <div className="subtitle">
                <span>Explore what your favourite communities </span>

                <div class="vertical-slide-text-container">
                  <div class="vertical-slide-text">
                    <div class="text-item textLove">LOVE 😇</div>
                    <div class="text-item textHate">HATE 😈</div>
                    <div class="text-item textLearn">LEARN FROM 🤓</div>
                    <div class="text-item textLaugh">LAUGH AT 😆</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="communityCardsWrapper">
              {communities?.map((community, index) => (
                <CommunityCard key={community.uuid} community={community} />
              ))}
              {/* <CommunityCard />
                            <CommunityCard />
                            <CommunityCard />
                            <CommunityCard />
                            <CommunityCard />
                            <CommunityCard />
                            <CommunityCard /> */}
            </div>
          </div>
        </DivWithScrollDirectionListener>
      </div>
      {/*************************************************** /MAIN CONTENT */}
    </>
  );
};

export default HomePage;
