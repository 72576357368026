import axios from "axios";
import AuthHelper from "../helper-methods/auth-helper";

const publicAxiosConfig = {
  baseURL: `${window.REACT_APP_AUTH_ENDPOINT_BASE_URL}/resource-management/api/v1/pub`,
};

const privateAxiosConfig = {
  baseURL: `${window.REACT_APP_AUTH_ENDPOINT_BASE_URL}/resource-management/api/v1`,
};

const publicExploreAxiosConfig = {
  baseURL: `${window.REACT_APP_AUTH_ENDPOINT_BASE_URL}/explore/api/v1/pub`,
};

const privateExploreAxiosConfig = {
  baseURL: `${window.REACT_APP_AUTH_ENDPOINT_BASE_URL}/explore/api/v1`,
};

const publicCommentManagementAxiosConfig = {
  baseURL: `${window.REACT_APP_AUTH_ENDPOINT_BASE_URL}/comments-mgmt/api/v1`,
};

const privateCommentManagementAxiosConfig = {
  baseURL: `${window.REACT_APP_AUTH_ENDPOINT_BASE_URL}/comments-mgmt/api/v1`,
};

const privateBotSupportAxiosConfig = {
  baseURL: `${window.REACT_APP_AUTH_ENDPOINT_BASE_URL}/bot-support/api/v1`,
};

const privateNotificationAxiosConfig = {
  baseURL: `${window.REACT_APP_AUTH_ENDPOINT_BASE_URL}/notification-api/api/v1`,
};

const publicV2Config = {
  baseURL: `${window.REACT_APP_AUTH_ENDPOINT_BASE_URL}/resourcemgmt-api/api/v2`,
};

const privateV2Config = {
  baseURL: `${window.REACT_APP_AUTH_ENDPOINT_BASE_URL}/resourcemgmt-api/api/v2`,
};

const premiumV2Config = {
  baseURL: `${window.REACT_APP_AUTH_ENDPOINT_BASE_URL}/resourcemgmt-api/api/v2/prem`,
};

const aiinsightsAxiosConfig = {
  baseURL: `${window.REACT_APP_AUTH_ENDPOINT_BASE_URL}/ai-insight`,
};

const resourceExternalAxiosConfig = {
  baseURL: `${window.REACT_APP_AUTH_ENDPOINT_BASE_URL}/resource-external/api/v1`,
};

let PublicHttpClient = axios.create(publicAxiosConfig);

let ProtectedHttpClient = axios.create(privateAxiosConfig);

let PublicV2Client = axios.create(publicV2Config);

let ProtectedV2Client = axios.create(privateV2Config);

let PublicExploreHttpClient = axios.create(publicExploreAxiosConfig);

let ProtectedExploreHttpClient = axios.create(privateExploreAxiosConfig);

let PublicCommentManagementHttpClient = axios.create(
  publicCommentManagementAxiosConfig
);

let ProtectedCommentManagementHttpClient = axios.create(
  privateCommentManagementAxiosConfig
);

let ProtectedBotSupportHttpClient = axios.create(privateBotSupportAxiosConfig);

let ProtectedNotifcationHttpClient = axios.create(
  privateNotificationAxiosConfig
);

let PremiumV2Client = axios.create(premiumV2Config);

const AiInsightsHttpClient = axios.create(aiinsightsAxiosConfig);

const ResourceExternalHttpClient = axios.create(resourceExternalAxiosConfig);

// Set the AUTH token for any request
ProtectedHttpClient.interceptors.request.use(function (config) {
  const token = AuthHelper.getToken();
  if (token?.length) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
  }
  return config;
});

// Set the AUTH token for any request
ProtectedExploreHttpClient.interceptors.request.use(function (config) {
  const token = AuthHelper.getToken();
  if (token?.length) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
  }
  return config;
});

ProtectedCommentManagementHttpClient.interceptors.request.use(function (
  config
) {
  const token = AuthHelper.getToken();
  if (token?.length) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
  }
  return config;
});

ProtectedBotSupportHttpClient.interceptors.request.use(function (config) {
  const token = AuthHelper.getToken();
  if (token?.length) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
  }
  return config;
});

ProtectedNotifcationHttpClient.interceptors.request.use(function (config) {
  const token = AuthHelper.getToken();
  if (token?.length) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
  }
  return config;
});

ProtectedV2Client.interceptors.request.use(function (config) {
  const token = AuthHelper.getToken();
  if (token?.length) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
  }
  return config;
});

PremiumV2Client.interceptors.request.use(function (config) {
  const token = AuthHelper.getToken();
  if (token?.length) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
  }
  return config;
});

ResourceExternalHttpClient.interceptors.request.use(function (config) {
  const token = AuthHelper.getToken();
  if (token?.length) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
  }
  return config;
});

export const HttpClient = {
  ProtectedHttpClient,
  PublicHttpClient,
  PublicExploreHttpClient,
  ProtectedExploreHttpClient,
  ProtectedBotSupportHttpClient,
  PublicCommentManagementHttpClient,
  ProtectedCommentManagementHttpClient,
  ProtectedNotifcationHttpClient,
  PublicV2Client,
  ProtectedV2Client,
  PremiumV2Client,
  AiInsightsHttpClient,
  ResourceExternalHttpClient,
};
