import React, { Component, useEffect, useState } from "react";
import "./resource-wizard.scss";
import ResourceLinkInputStep from "../resource-link-input-step/resource-link-input-step";
import ResourceEditStep from "../resource-edit-step/resource-edit-step";
import ResourceTypeEditStep from "../resource-type-edit-step/resource-type-edit-step";
import ResourceTopicEditStep from "../resource-topic-edit-step/resource-topic-edit-step";
import ResourceWizardBullets from "../resource-wizard-bullets/resource-wizard-bullets";
import AIAssitanceFeedback from "../ai-assistance-feedback/ai-assistance-feedback";
import { useSelector } from "react-redux";
import { EventEmitter } from "../../../../shared/utils/event-emitter";
import SiteWiseEvents from "../../../../declarations/site-wise-events";
import ResourceUtils from "../../resource-utils";
import ResourceWizardSteps from "../../constants/resource-wizard-steps";
import ResourceWizardMobileButtons from "../resource-wizard-mobile-buttons/resource-wizard-mobile-buttons";
import MobileDrawer from "../../../../components/mobile-drawer/mobile-drawer";
import ICONS from "../../../../assets/quester-icons";

const ResourceWizard = ({ isMobile = false }) => {
  const { currentStep, wizardEnabled } = useSelector(
    (state) => state.resourceWizardStore
  );

  const _listenToEvents = () => {
    EventEmitter.subscribe(SiteWiseEvents.SHOW_RESOURCE_ADD_WIZARD, () => {
      ResourceUtils.showWizard();
    });
  };

  const _removeListeners = () => {
    EventEmitter.cancelAll(SiteWiseEvents.SHOW_RESOURCE_ADD_WIZARD);
  };

  useEffect(() => {
    _listenToEvents();
    return () => {
      _removeListeners();
    };
  }, []);

  const _renderStep = () => {
    switch (currentStep) {
      case ResourceWizardSteps.LINK_INPUT:
        return <ResourceLinkInputStep />;
      case ResourceWizardSteps.META_DATA_EDIT:
        return <ResourceEditStep />;
      case ResourceWizardSteps.PREVIEW_WITH_TYPE_EDIT:
        return <ResourceTypeEditStep />;
      case ResourceWizardSteps.PREVIEW_WITH_TOPIC_EDIT:
        return <ResourceTopicEditStep />;
      default:
        return <ResourceLinkInputStep />;
    }
  };

  if (!wizardEnabled) {
    return <></>;
  }

  if (isMobile) {
    return (
      <MobileDrawer
        isActive={true}
        onClose={(e) => {
          ResourceUtils.resetWizard();
        }}
        headerTitle="ADD RECOMMENDATION"
        headerIcon={ICONS.plus}
        extraClass="addItemDrawer"
      >
        <div className="addItemFlowWrapper">{_renderStep()}</div>
      </MobileDrawer>
    );
  }

  return <div className="addItemFlowWrapper">{_renderStep()}</div>;
};

export default ResourceWizard;
